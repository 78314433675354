<!-- Modal for firefox and IE-->
<div *ngIf="navigator" class="fondo-negro animated fadeIn" [ngClass]="modalUserTypeService.hide">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button (click)="closeModal()" type="button" class="close close-firefox-ie" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="cards">
                    <h5 class="welcome-subtitle general-title cursor">{{ 'MODAL-USER-TYPE.IAM' | transloco }}</h5>
                <div class="clearfix"></div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('student')" class="pointer">
                            <img src="./assets/images/images/student-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.STUDENT' | transloco }}">
                            <div class="card-body">
                                <p class="card-text subtitle">{{ 'MODAL-USER-TYPE.STUDENT' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('teacher')" class="pointer">
                            <img src="./assets/images/images/teacher-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}">
                            <div class="card-body">
                                <p class="card-text subtitle">{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('teacher')" class="pointer">
                            <img src="./assets/images/images/parent-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}">
                            <div class="card-body">
                                <p class="card-text subtitle">{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- general modal -->
<div *ngIf="!navigator" class="fondo-difuminado animated fadeIn" [ngClass]="modalUserTypeService.hide">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="cards">
                    <h5 class="welcome-subtitle general-title cursor">{{ 'MODAL-USER-TYPE.IAM' | transloco }}</h5>
                <div class="clearfix"></div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('student')" class="pointer">
                            <img src="./assets/images/images/student-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.STUDENT' | transloco }}">
                            <div class="card-body">
                                <p class="card-text subtitle">{{ 'MODAL-USER-TYPE.STUDENT' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('teacher')" class="pointer">
                            <img src="./assets/images/images/teacher-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}">
                            <div class="card-body">
                                <p class="card-text card-text-teacher subtitle">{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <a (click)="applyUserType('parent')" class="pointer">
                            <img src="./assets/images/images/parent-btn@2x.png" class="card-img-top" alt="{{ 'MODAL-USER-TYPE.TEACHER' | transloco }}">
                            <div class="card-body">
                                <p class="card-text-parents subtitle">{{ 'MODAL-USER-TYPE.PARENTS' | transloco }}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>