import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  /**
   * The language variable is declare
   * @param language Variable to save the actual language
   */
  language: string;

  constructor(
    private languageService: LanguageService,
    private meta: Meta
  ) {
    this.language = this.languageService.getLanguage();
  }

  generateTags( config ) {
    config = {
      title: 'TeacherPortal',
      description: 'Lab4U',
      image: '',
      slug: '',
      keywords: '',
      ...config
    };

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@Lab_4U' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'TeacherPortal' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://teachers.lab4u.co/${config.slug}` });
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    this.meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW'} );
    this.meta.updateTag({ name: 'author', content: 'Lab4U' });
    this.meta.updateTag({ charset: 'UTF-8' });
    if (this.language === 'es') {
      // tslint:disable-next-line: max-line-length
      this.meta.updateTag({ name: 'keywords', content: 'Lab4U, Lab4foru, lab for you,  Lab4Physics, Lab4Chemistry, Experimentos de física, experimentos de química, experimentos de ciencias, contenido gratis, contenido gratuito, experimentos en casa, indagacion cientifica, sensores smartphones, accelerometro, sonometro, graficador móvil, newton, STEM' });
    } else {
      // tslint:disable-next-line: max-line-length
      this.meta.updateTag({ name: 'keywords', content: 'Lab4U, Lab4foru, lab for you, Lab4Physics, Lab4Chemistry, Physics experiments, chemistry experiments, science experiments, free content, home experiments, hands-on science, inquiry based learning , smartphone sensors, accelerometer, sonometer, mobile graphing, newton, STEM' });
    }

  }

}
