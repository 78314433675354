import { RouterModule, Routes } from '@angular/router';

// Components
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { TutoriaComponent } from './tutoria/tutoria.component';
import { PagesComponent } from './pages/pages.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

// Guards
import { LoginGuard } from './services/guards/login.guard';
import { SelectAppGuard } from './services/guards/select-app.guard';
import { SelectUserTypeGuard } from './services/guards/select-user-type.guard';
import { IsLoginGuard } from './services/guards/is-login.guard';
import { RedirectFromHomeGuard } from './services/guards/redirect-from-home.guard';
import { HomeComponent } from './home/home.component';
import { LogOutComponent } from './log-out/log-out.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { CompleteDeleteAccountComponent } from './complete-delete-account/complete-delete-account.component';

const appRoutes: Routes = [
  {
    path: 'sign-in',
    canActivate: [IsLoginGuard],
    component: LoginComponent,
    data: { tituloES: 'Iniciar sesión', tituloEN: 'Sign in' },
  },
  {
    path: 'sign-in/:apple-response',
    canActivate: [IsLoginGuard],
    component: LoginComponent,
    data: { tituloES: 'Iniciar sesión', tituloEN: 'Sign in' },
  },
  {
    path: 'forgot-password',
    canActivate: [IsLoginGuard],
    component: ForgotPasswordComponent,
    data: { tituloES: 'Olvidaste la contraseña', tituloEN: 'Forgot password' },
  },
  {
    path: 'sign-up',
    component: RegisterComponent,
    canActivate: [SelectUserTypeGuard],
    data: { tituloES: 'Registrarse', tituloEN: 'Sign up' },
  },
  {
    path: 'home',
    canActivate: [LoginGuard],
    component: HomeComponent,
  },
  {
    path: 'log-out',
    canActivate: [LoginGuard],
    component: LogOutComponent,
  },
  {
    path: 'request-account-data-deletion',
    component: DeleteAccountComponent,
    canActivate: [],
  },
  {
    path: 'complete-account-data-deletion',
    component: CompleteDeleteAccountComponent,
    canActivate: [],
  },
  //* TutorIA *//
  {
    path: 'tutoria',
    component: TutoriaComponent,
    canActivate: [],
    data: { tituloES: null, tituloEN: null },
  },
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '**',
    canActivate: [RedirectFromHomeGuard],
    component: NopagefoundComponent,
  },
];

/**
 * @ignore
 */
export const APP_ROUTES = RouterModule.forRoot(appRoutes);
