import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Service
import { ModalUserTypeService } from './modal-user-type.service';

// Alerts
import Swal from 'sweetalert2';

// Device detector
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Component for the user to select an user type
 */
@Component({
  selector: 'app-modal-user-type',
  templateUrl: './modal-user-type.component.html',
  styleUrls: ['./modal-user-type.component.css'],
})
export class ModalUserTypeComponent implements OnInit {
  navigator = false;
  deviceInfo = null;

  /**
   * Following variables declared
   * @param modalUserTypeService Variable to show/hide modal
   * @param router Variable that manage the routes
   */
  constructor(
    public modalUserTypeService: ModalUserTypeService,
    public router: Router,
    private deviceService: DeviceDetectorService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (
      this.deviceInfo.browser === 'Firefox' ||
      this.deviceInfo.browser === 'IE'
    ) {
      this.navigator = true;
    }
  }

  /**
   * @ignore
   */
  ngOnInit() {}

  /**
   * Function that allows to close the modal using the service of the modal
   */
  closeModal() {
    this.modalUserTypeService.hideModal();
    return true;
  }

  applyUserType(userType: string) {
    if (
      userType === 'student' ||
      userType === 'teacher' ||
      userType === 'parent'
    ) {
      localStorage.setItem('Type', JSON.stringify(userType));
      this.router.navigate(['/sign-up']);
      return true;
    } else {
      Swal.fire('Error', 'error', 'error');
      this.closeModal();
      return false;
    }
  }
}
