/**
 * User’s model
 */
export class User {
  /**
   * Variable declarations
   * @param first_name Variable to save user’s name
   * @param last_name Variable to save user’s last name
   * @param email Variable to save user’s email
   * @param language Variable to save user’s language
   * @param login_type Variable to save the origin of the user’s register
   * @param type Variable to save type of user
   * @param gender Variable to save user’s gender
   * @param password Variable to save user’s password
   * @param biz_type Variable to save if the user is B2B, B2C2B, etc
   * @param app Variable to save the origin app of the user
   * @param isGuest Variable to save if the account is guest user
   * @param institution Variable to save the user’s institution
   * @param actual_deal Variable to save the user’s plan (FREEMIUM, PREMIUM)
   * @param institution_id Variable to save the institution ID of the user
   * @param usergroup Variable to save the user group to which it belongs
   * @param token Variable to save the user’s token
   * @param validated_acount Variable to save if the user validated the account
   * @param image Variable to save the user’s image
   * @param userId Variable to save the user’s ID
   * @param user_type Variable to save the same as the login_type, the Api request it to be this way
   */
  constructor(
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public language?: string,
    public login_type?: string,
    public type?: string,
    public gender?: string,
    public password?: string,
    public country?: string,
    public biz_type?: string,
    public app?: string,
    public isGuest?: boolean,
    public deal?: string,
    public institution?: string,
    public actual_deal?: string,
    public institution_id?: string,
    public usergroup?: string,
    public token?: string,
    public validated_acount?: boolean,
    public image?: string,
    public userId?: string,
    public user_type?: string,
    public third_party_id?: string,
  ) {}
}

export interface TeacherInfoResDTO {
  deal: { id: string; name: string };
  institution: { id: string; name: string };
  classList: {
    id: string;
    name: string;
    isAssigned: boolean;
    nStudents: number;
  }[];
}

export interface TeacherAssignClassroomDTO {
  user_id: string;
  deal_id: string;
  classroom_list: string[];
  remove_old?: boolean;
}
