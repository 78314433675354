import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { LanguageService } from './services/language/language.service';

/**
 * External library to translate the page
 */
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  /**
   * A variable is defined to save a language
   */
  language: string;

  /**
   * @ignore
   */
  constructor(
            private http: HttpClient,
            private languageService: LanguageService
  ) {
    this.getTranslation();
  }

  /**
   * Function called {@link ./LanguageService.html|languageService.getLanguage()} which applies the detected language on localStorage
   */
  getTranslation() {
    const lang = this.languageService.getLanguage();
    return this.http.get<Translation>(`../assets/i18n/${lang}.json`);
  }

}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'es'],
        defaultLang: 'es',
        fallbackLang: 'es',
        prodMode: environment.production,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
