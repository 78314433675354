import { environment } from 'src/environments/environment';

let API_env: string = environment.api_url;

/**
 * Constant of the call to the APIS .
 */
export const API_V1_URL = API_env;

/**
 * Constant of the call to the API V3 .
 */
export const API_V3_URL = environment.api_v3_url;
