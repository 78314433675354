<div *transloco="let text; read: 'DELETE-ACCOUNT-CONFIRMATION'">
  <!-- TOP NAVBAR -->
  <header class="topbar">
    <nav class="navbar top-navbar navbar-expand">
      <!-- LOGO -->
      <div class="">
        <a class="navbar-brand" routerLink="/sign-in">
          <div class="navbar-brand-wrap row">
            <!-- IMAGE -->
            <div
              class="resp-img-container d-block d-sm-none my-auto topbar-icon mr-3"
            >
              <img
                class="logo-image"
                src="./assets/images/gallery/portal-logo.png"
                alt="homepage"
              />
            </div>

            <!-- TEXT -->
            <div class="resp-img-container my-auto d-none d-sm-block">
              <img
                class="logo-text-image"
                src="./assets/images/gallery/portal-ext-logo.png"
                alt="homepage"
                height="100"
              />
            </div>
          </div>
        </a>
      </div>

      <!-- MENU ELEMENTS -->
      <div class="navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <!-- LANGUAGE SELECTORS -->
          <li class="nav-item language flat-button row">
            <div class="my-auto">
              <a (click)="changeLanguage('en')" class="change-language pointer"
                >EN
              </a>
              <span class="cursor separation">|</span>
              <a (click)="changeLanguage('es')" class="change-language pointer">
                ES</a
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <div class="p-4 p-md-5 m-md-5">
    <mat-spinner class="m-auto" *ngIf="loading"></mat-spinner>
    <div class="" *ngIf="requestConfirmed">
      <h1 class="delete-account-title">{{ text('CONFIRMED-TITLE') }}</h1>
      <p class="pb-3">
        {{ text('CONFIRMED-SUBTITLE') }}
      </p>
    </div>
    <div *ngIf="errorCode === 400 || errorCode === 404 || errorCode === 422">
      <h1 class="delete-account-title">{{ text('INVALID-TITLE') }}</h1>
      <p class="pb-3">
        {{ text('INVALID-SUBTITLE') }}
      </p>
    </div>
    <div *ngIf="errorCode === 409">
      <h1 class="delete-account-title">
        {{ text('ALREADY-CONFIRMED-TITLE') }}
      </h1>
      <p class="pb-3">
        {{ text('ALREADY-CONFIRMED-SUBTITLE') }}
      </p>
    </div>
    <div *ngIf="errorCode === 420">
      <h1 class="delete-account-title">{{ text('EXPIRED-TITLE') }}</h1>
      <p class="pb-3">
        {{ text('EXPIRED-SUBTITLE') }}
      </p>
    </div>
    <div *ngIf="errorCode === 500">
      <h1 class="delete-account-title">{{ text('ERROR-TITLE') }}</h1>
      <p class="pb-3">
        {{ text('ERROR-SUBTITLE') }}
      </p>
    </div>
  </div>
</div>
