import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Service
import { UserService } from '../user/user.service';
import { MixpanelService } from '../analytics/mixpanel.service';

/**
 * Guard to force the user to choose an app
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  /**
   * Following variables declared
   * @param userService Service that bring user token
   * @param router Variable that manage the routes
   */
  constructor(
    public userService: UserService,
    public router: Router,
    public mixpanelService: MixpanelService,
  ) {}
  direc;
  /**
   * Verify that the user has logged in
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.direc = state.url;
    if (!this.userService.isLogin()) {
      this.router.navigate(['/sign-in'], {
        queryParams: { redirectUrl: state.url },
      });
      return false;
    }
    let authentication = localStorage.getItem('new_user_login') || '';
    if (authentication == 'true') return this.verifyUserToken();
    else {
      this.userService.logout();
      return false;
    }
  }

  verifyUserToken(): Observable<boolean> {
    const userId = localStorage.getItem('userId');
    const userToken = localStorage.getItem('token');
    return this.userService.verifyToken(userId, userToken).pipe(
      map((isTokenValid) => {
        if (!isTokenValid) this.userService.logout();
        return isTokenValid;
      }),
    );
  }
}
