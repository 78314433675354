import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';

// Models
import { User } from '../../models/user.model';

// Services
import { UserService } from '../../services/user/user.service';
import { SidebarService } from '../../services/shared/sidebar.service';
import { SettingsService } from '../../services/settings/settings.service';
import { LanguageService } from '../../services/language/language.service';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { PrivateRoutes } from 'src/app/config/config.routes';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';

/**
 * Component to manage the sidebar component
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  /**
   * The color variable is declared
   * @param color Variable to keep colour
   */
  color: string;
  /**
   * The theme variable is declared
   * @param theme Variable to keep page theme
   */
  theme: string;
  /**
   * The language variable is declared
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * The user variable is declared
   * @param user Variable to save the connected user data
   */
  user: User;
  redirect: string;

  /** Items of the Menu */
  @Input() sidebarElements: any;

  userId = this.userService.getId();

  homeSection: string = '';

  /**
   *   @param toggleSidebar to change state of the sidebar
   */
  toggleSidebar: boolean = false;
  /**
   * Following variables declared
   * @param userService Service that brings users data and functions
   * @param settings Service that works the page colour
   * @param sidebarService Service that works with the sidebar and the app of the page
   * @param languageService Service that works with the page language
   */
  constructor(
    public userService: UserService,
    public settings: SettingsService,
    public sidebarService: SidebarService,
    public languageService: LanguageService,
    public mixpanelService: MixpanelService,
    public router: Router,
  ) {
    this.language = languageService.getLanguage();
    this.color = settings.getColor();
  }

  /**
   * Data is saved in the {@link ../injectables/UserService.html|userService.user} on the user variable
   */
  ngOnInit() {
    this.user = this.userService.user;

    this.homeSection = PrivateRoutes.HOME;

    // this.mixpanelService.init(this.userId);

    // Define the elements of the sidebar menu depending on the language
    // if (this.language === 'es')
    //   this.sidebarElements = this.sidebarService.menuES;
    // else this.sidebarElements = this.sidebarService.menuEN;
  }

  /**
   * Function that applies colour, changing the app. At the end does a reload to make the new requests
   * @param theme Variable that brings the colour you want to apply according to the selection made by the user
   */
  changeColor(theme: string) {
    const route = this.router.url.substring(0, 6);
    this.theme = theme;
    this.settings.applyTheme(this.theme);
    this.color = this.settings.getColor();
    if (this.color === 'red') {
      this.sidebarService.applyApp('LAB4PHYSICS');
    } else if (this.color === 'green') {
      this.sidebarService.applyApp('LAB4BIOLOGY');
    } else {
      this.sidebarService.applyApp('LAB4CHEMISTRY');
    }
    if (this.router.url.length > 25) {
      if (route === '/exper') {
        this.router.navigate(['/categories']);
      } else if (route === '/tool/') {
        this.router.navigate(['/tools']);
      } else {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  }

  sidebarShowSection(section_title) {
    this.mixpanelService.track(
      `sidebar_show_section_${section_title.slice(1)}`,
      { 'User type': this.userService.getRole() },
    );
  }

  toggleShowSidebar = () => {
    this.toggleSidebar = !this.toggleSidebar;
  };

  leftSidebarSelected(section_url) {
    if (section_url === '/general-documents') {
      this.mixpanelService.track(
        mixpanelEvent.LEFT_SIDEBAR_RESOURCES_SELECTED,
        {
          source_name: 'LAB4UPORTAL',
        },
      );
    }
    if (section_url === '/home') {
      this.mixpanelService.track(
        mixpanelEvent.LEFT_SIDEBAR_HOME_BUTTON_SELECTED,
        {
          source_name: 'LAB4UPORTAL',
        },
      );
    }
    if (section_url === '/log-out') {
      this.mixpanelService.track(mixpanelEvent.LEFT_SIDEBAR_SIGNOUT_SELECTED, {
        source_name: 'LAB4UPORTAL',
      });
    }
    this.toggleShowSidebar();
  }

  logoSelected() {
    this.mixpanelService.track(
      mixpanelEvent.LEFT_SIDEBAR_LAB4UPORTAL_LOGO_SELECTED,
      {
        source_name: 'LAB4UPORTAL',
      },
    );
  }

  supportSelected() {
    this.mixpanelService.track(mixpanelEvent.LEFT_SIDEBAR_SUPPORT_SELECTED, {
      source_name: 'LAB4UPORTAL',
    });
  }
}
