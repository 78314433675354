import { Injectable, EventEmitter } from '@angular/core';

/**
 * Service that works with the modal-paywall
 */
@Injectable({
  providedIn: 'root'
})
export class ModalPaywallService {

  /**
   * The hide variable is declare
   * @param hide Variable to hide the modal
   */
  public hide = 'hide';
  /**
   * The notification variable is declare
   * @param notification Variable to send notifications
   */
  public notification = new EventEmitter<any>();

  /**
   * @ignore
   */
  constructor() {}

  /**
   * Function that allows to hide the modal
   */
  hideModal() {
    this.hide = 'hide';
    return this.hide;
  }

  /**
   * Function that allows to show the modal
   */
  showModal() {
    this.hide = '';
    return this.hide;
  }

}
