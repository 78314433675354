import { Injectable, EventEmitter } from '@angular/core';

/**
 * Service that works with the modal-video
 */
@Injectable({
  providedIn: 'root',
})
export class ModalVideoService {
  /**
   * The video variable is declare
   * @param video Variable to save the link from Vimeo
   */
  public video = '';
  /**
   * The hide variable is declare
   * @param hide Variable to hide the modal
   */
  public hide = 'hide';

  /**
   * @ignore
   */
  constructor() {}

  /**
   * Function that allows to hide the modal
   */
  hideModal() {
    this.hide = 'hide';
    this.video = '';
    return this.hide;
  }

  /**
   * Function that allows to show the modal
   * @param url Variable that brings the ID from the YouTube video
   */
  showModal(url: string) {
    this.video = url.replace('watch?v=', 'embed/');
    this.video += '?autoplay=1';
    this.hide = '';
    return this.video;
  }

  showModalVideoIdOnly(video_id: string) {
    this.video = 'https://www.youtube.com/embed/' + video_id + '?autoplay=1';
    this.hide = '';
    return this.video;
  }
}
