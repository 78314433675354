<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
  <div class="col-md-12 align-self-center" *ngIf="breadcrumbsTitle">
    <h1 class="text-themecolor general-title cursor m-0 mb-3">
      {{ breadcrumbsTitle }}
    </h1>
  </div>

  <div class="toggle">
    <!-- <button (click)="showPipedrive()" class="right-side-toggle waves-effect waves-light btn-inverse btn btn-circle btn-sm pull-right m-l-10"><i class="fas fa-headphones-alt"></i></button> -->
    <a target="_blank" href="https://lab4u.zendesk.com/hc/en-us/requests/new">
      <button
        value="{{ this.hover_help }}"
        class="global-cta-btn waves-effect waves-light btn btn-inverse"
      >
        <div class="row m-0">
          <!-- <i class="fas fa-headphones-alt m-auto"></i> -->
          <i class="far fa-question-circle m-auto"></i>
        </div>
      </button>
    </a>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
