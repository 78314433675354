import { NgModule } from '@angular/core';

// NgFor, NgIF, pipes, etc
import { CommonModule } from '@angular/common';

// RouterLink -> sidebar
import { RouterModule } from '@angular/router';

// Markdown formatting
import { MarkdownModule } from 'ngx-markdown';

// Pipes
import { PipesModule } from '../pipes/pipes.module';

// Components
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './footerMenu/footer.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { ModalVideoComponent } from '../components/modal-video/modal-video.component';
import { ModalPaywallComponent } from '../components/modal-paywall/modal-paywall.component';
import { ModalPdfComponent } from '../components/modal-pdf/modal-pdf.component';

// Translator
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from './loader/loader.component';

//directive
import { HasRoleDirective } from '../directives/has-role.directive';
import { SectionButtonComponent } from './section-button/section-button.component';
import { AppsCardComponent } from './apps-card/apps-card.component';
import { AppsCardsListComponent } from './apps-cards-list/apps-cards-list.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { NewsComponent } from './news/news.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule } from '@angular/forms';
import { NoResourcesComponent } from './no-resources/no-resources.component';
import { MaterialModules } from '../material-modules';
import { QuizzesFilterComponent } from './quizz-filters/quizzes-filter/quizzes-filter.component';
import { DateFilterComponent } from './quizz-filters/date-filter/date-filter.component';
import { ClassroomFilterComponent } from './quizz-filters/classroom-filter/classroom-filter.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GeneralChartComponent } from './quizz-results-charts/general-chart/general-chart.component';
import { TimeDisplayComponent } from './quizz-results-charts/time-display/time-display.component';
import { ChartWithCategoriesComponent } from './quizz-results-charts/chart-with-categories/chart-with-categories.component';
import { QuizQuestionsComponent } from './quiz-questions/quiz-questions.component';
import { FixComponent } from './fix/fix.component';
import { SelectComponent } from './select/select.component';
import { BarHorizontalComponent } from './bar-horizontal/bar-horizontal.component';
import { BarHorizontalEvaluationComponent } from './bar-horizontal-evaluation/bar-horizontal-evaluation.component';
import { FilterQuizComponent } from './evaluation-result/filter-quiz/filter-quiz.component';
import { ChartComponent } from './evaluation-result/chart/chart.component';
import { ChatbotComponent } from './chatbot/chatbot.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    PipesModule,
    TranslocoModule,
    FormsModule,
    NgxChartsModule,
    MaterialModules,
    MarkdownModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NopagefoundComponent,
    ModalVideoComponent,
    ModalPaywallComponent,
    ModalPdfComponent,
    LoaderComponent,
    HasRoleDirective,
    SectionButtonComponent,
    AppsCardComponent,
    AppsCardsListComponent,
    PrimaryButtonComponent,
    NewsComponent,
    SearchBarComponent,
    NoResourcesComponent,
    QuizzesFilterComponent,
    DateFilterComponent,
    ClassroomFilterComponent,
    GeneralChartComponent,
    TimeDisplayComponent,
    ChartWithCategoriesComponent,
    QuizQuestionsComponent,
    FixComponent,
    SelectComponent,
    BarHorizontalComponent,
    BarHorizontalEvaluationComponent,
    FilterQuizComponent,
    ChartComponent,
    ChatbotComponent,
  ],
  exports: [
    ChartComponent,
    BarHorizontalComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    ModalVideoComponent,
    ModalPaywallComponent,
    ModalPdfComponent,
    TranslocoModule,
    LoaderComponent,
    HasRoleDirective,
    SectionButtonComponent,
    AppsCardComponent,
    AppsCardsListComponent,
    PrimaryButtonComponent,
    NewsComponent,
    SearchBarComponent,
    NoResourcesComponent,
    MaterialModules,
    ClassroomFilterComponent,
    DateFilterComponent,
    QuizzesFilterComponent,
    NgxChartsModule,
    GeneralChartComponent,
    TimeDisplayComponent,
    ChartWithCategoriesComponent,
    QuizQuestionsComponent,
    FixComponent,
    SelectComponent,
    BarHorizontalEvaluationComponent,
    FilterQuizComponent,
    ChatbotComponent,
  ],
})
export class SharedModule {}
