import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = environment.secret_provider_url;
  public fetchdata: any;

  constructor(private http: HttpClient) {}

  fetchData(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      secret_names: [
        'apiV1Key',
        'apiV3Key',
        'mailchimpApiKey',
        'mailchimpDataCenter',
        'mailchimpList',
        'mixpanelProjectKey',
        'TOKEN',
        'TokenSHA1',
      ],
    };

    return this.http.post<any>(this.apiUrl, body, { headers });
  }
}
