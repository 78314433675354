<!-- TOP NAVBAR -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand navbar-light">

    <!-- LOGO -->
    <div class="">

      <a class="navbar-brand" routerLink="/sign-in">

        <div class="navbar-brand-wrap row">

          <!-- IMAGE -->
          <div class="resp-img-container d-block d-sm-none my-auto topbar-icon mr-3">
            <img class="logo-image" src="./assets/images/gallery/portal-logo.png" alt="homepage" />
          </div>

          <!-- TEXT -->
          <div class="resp-img-container my-auto d-none d-sm-block">
            <img class="logo-text-image" src="./assets/images/gallery/portal-ext-logo.png" alt="homepage" height="100"/>
          </div>

        </div>

      </a>

    </div>

    <!-- MENU ELEMENTS -->
    <div class="navbar-collapse">

      <ul class="navbar-nav ml-auto">

        <!-- SING-IN CTA -->
        <li class="nav-item dropdown row">
          <a routerLink="/sign-in" class="register dropdown-item flat-button pointer p-0 my-auto">
            {{ 'SIGN-IN.TITLE' | transloco }}
          </a>
        </li>

        <!-- SEPARATOR -->
        <li class="nav-item dropdown mx-2" style="font-size: 30px;">
          <label class="separation"> | </label>
        </li>

        <!-- LANGUAGE SELECTORS -->
        <li class="nav-item language flat-button row">
          <div class="my-auto">
            <a (click)="changeLanguage('en')" class="change-language pointer">EN </a>
            <span class="cursor separation">|</span>
            <a (click)="changeLanguage('es')" class="change-language pointer"> ES</a>
          </div>
        </li>

      </ul>

    </div>
  </nav>
</header>


<!-- ============================================================== -->
<!-- Main wrapper -->
<!-- ============================================================== -->
<section class="sing-up-wrap mx-auto row px-4 px-md-5">
  <div class="my-auto sing-up">

        <form ngNativeValidate [formGroup]="form" (ngSubmit)="registerUser()"
          class="form-horizontal form-sing-up row mx-auto my-5 my-md-0"
          id="loginform" action="index.html">

          <div class="row m-0 w-100 mb-3">
            <h1 class="general-title cursor mx-auto">{{ 'SIGN-UP.TITLE' | transloco }}</h1>
          </div>

          <!-- FIRST NAME | LAST NAME -->
          <div class="row m-0 w-100">
            <div class="form-group first-name col-12 col-md-6 pr-0 pr-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.FIRST-NAME' | transloco }}</span>
              <input formControlName="first_name" name="first_name" class="form-control" type="text" required
                placeholder="{{ 'SIGN-UP.PLACEHOLDER-FIRST-NAME' | transloco }}">
            </div>
            <div class="form-group last-name col-12 col-md-6 pl-0 pl-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.LAST-NAME' | transloco }}</span>
              <input formControlName="last_name" name="last_name" class="form-control" type="text" required
                placeholder="{{ 'SIGN-UP.PLACEHOLDER-LAST-NAME' | transloco }}">
            </div>
          </div>

          <!-- EMAIL | COUNTRY -->
          <div class="row m-0 w-100">
            <div class="form-group email col-12 col-md-6 pr-0 pr-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.EMAIL' | transloco }}</span>
              <input formControlName="email" name="email" class="form-control" type="email" required
                placeholder="{{ 'SIGN-UP.PLACEHOLDER-EMAIL' | transloco }}">
            </div>
            <div class="form-group country col-12 col-md-6 pl-0 pl-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.COUNTRY' | transloco }}</span><br />
              <select formControlName="country" (change)="selectChangeHandler($event)" class="form-control" required>
                <option *ngFor="let country of countries" value={{country.name}}>{{country.name}}</option>
              </select>
            </div>
          </div>

          <!-- PASSWORD | CONFIRM PASSWORD -->
          <div class="row m-0 w-100">
            <div class="form-group password col-12 col-md-6 pr-0 pr-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.PASSWORD' | transloco }}</span>
              <input formControlName="pass" name="pass" class="form-control" type="password" required
                placeholder="{{ 'SIGN-UP.PLACEHOLDER-PASSWORD' | transloco }}">
              <span class="max-characters field-title cursor">{{ 'SIGN-UP.MIN-LENGTH' | transloco }}</span>
            </div>
            <div class="form-group confirm-password col-12 col-md-6 pl-0 pl-md-2">
              <span class="field-title cursor">{{ 'SIGN-UP.CONFIRM-PASSWORD' | transloco }}</span>
              <input formControlName="confirmPassword" name="confirmPassword" class="form-control" type="password"
                required placeholder="{{ 'SIGN-UP.PLACEHOLDER-CONFIRM-PASSWORD' | transloco }}">
            </div>
          </div>

          <!-- SING UP -->
          <div class="m-0 w-100">

            <div class="form-group sign-up mx-auto">
              <button class="btn btn-lg btn-block btn-rounded btn-cards call-to-action" type="submit">{{
                'SIGN-UP.SIGN-UP'
                | transloco }}</button>
            </div>

            <div class="form-group subscribe-checkbox mx-auto">
              <div class="checkbox checkbox-primary p-t-0">
                <input formControlName="conditions" name="conditions" id="checkbox-signup" type="checkbox">
                <label for="checkbox-signup" class="field-title">{{ 'SIGN-UP.SUBSCRIBE' | transloco }}</label>
              </div>
            </div>

          </div>

          <!-- SOCIAL SING-UP -->
          <div class="m-0 w-100 d-flex flex-column">

            <div class="register-options mx-auto mb-4">
              <span class="responsive-just-text"></span>

              <span class="cursor responsive-img field-title">

                <img src="../../assets/images/images/line.png" alt="" class="d-none d-sm-inline">
                {{'SIGN-UP.SOCIAL-SIGN-UP' | transloco }}
                <img src="../../assets/images/images/line.png" alt="" class="d-none d-sm-inline">

              </span>

            </div>

            <div class="social-options mx-auto" *ngIf="language === 'es'">

              <!-- <div (click)="socialSignUp('Facebook')" class="facebook pointer">
                            <img src="../../assets/images/Social-Login/registrar-fb.png" alt="{{ 'SIGN-UP.FACEBOOK' | transloco }}" title="{{ 'SIGN-UP.FACEBOOK' | transloco }}">
                        </div> -->
              <div (click)="socialSignUp('Google')" class="google pointer">
                <img src="../../assets/images/Social-Login/registrar-google.png"
                  alt="{{ 'SIGN-UP.GOOGLE' | transloco }}" title="{{ 'SIGN-UP.GOOGLE' | transloco }}">
              </div>
            </div>
            <div class="social-options mx-auto" *ngIf="language !== 'es'">
              <!-- <div (click)="socialSignUp('Facebook')" class="facebook pointer">
                <img src="../../assets/images/Social-Login/signup-fb.png" alt="{{ 'SIGN-UP.FACEBOOK' | transloco }}" title="{{ 'SIGN-UP.FACEBOOK' | transloco }}">
              </div> -->
              <div (click)="socialSignUp('Google')" class="google pointer">
                <img src="../../assets/images/Social-Login/signup-google.png" alt="{{ 'SIGN-UP.GOOGLE' | transloco }}"
                  title="{{ 'SIGN-UP.GOOGLE' | transloco }}">
              </div>

            </div>

          </div>
        </form>

      </div>
</section>
