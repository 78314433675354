<!-- Modal -->
<div class="fondo-negro animated fadeIn" [ngClass]="modalPdfService.hide">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <iframe [src]="modalPdfService.urlPdf | safe: 'resourceUrl'" style="width:100%; height:600px;" frameborder="0"></iframe>
            </div>
            <div class="modal-footer">
                <button (click)="closeModal()" type="button" class="btn btn-secondary btn-responsive">{{ 'MODALS.CLOSE' | transloco }}</button>
            </div>
        </div>
    </div>
</div>