<!-- ============================================================== -->
<!-- Login header -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" routerLink="/sign-in">
                <!-- Logo icon -->
                <b>
                    <img src="./assets/images/gallery/logo.png" alt="homepage" class="dark-logo" />
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <img src="./assets/images/gallery/logo-text.png" alt="homepage" class="dark-text" />
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown">
                    <a routerLink="/sign-up" class="register flat-button pointer"> {{ 'HEADER.SIGN-UP' | transloco }} </a>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Login header -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Main wrapper -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register" style="background-image:url(../../assets/images/images/forgot-pass.png);">
        <div class="login-box card">
            <div class="card-body">
                <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" (ngSubmit)="recoverPassword( f )">
                    <h3 class="box-title cursor general-title m-b-20">{{ 'FORGOT-PASSWORD.FORGOT-PSW' | transloco }}</h3>
                    <p class="paragraph cursor">{{ 'FORGOT-PASSWORD.PARAGRAPH' | transloco }}</p>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input [ngModel]="userEmail" name="userEmail" userEmail="email" class="form-control" type="email" required placeholder="{{ 'FORGOT-PASSWORD.PLACEHOLDER' | transloco }}">
                        </div>
                    </div>
                    <div class="form-group text-center p-b-10">
                        <div class="col-xs-12">
                            <button class="btn btn-lg btn-block call-to-action btn-rounded btn-cards" type="submit">{{ 'FORGOT-PASSWORD.SEND-EMAIL' | transloco }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->