import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectFromHomeGuard {
  private subscription: Subscription = new Subscription();
  url: string;

  constructor(public router: Router) {}

  canActivate() {
    this.subscription = this.router.events.subscribe((resp: any) => {
      (async () => {
        this.url = await resp.url;
      })();
    });
    return this.checkGuard();
  }

  checkGuard() {
    let aux = 0;
    setTimeout(() => {
      if (this.url === '/home') {
        this.router.navigate(['/sign-in']);
        aux++;
      }
    }, 100);
    return aux === 0;
  }
}
