import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Services
import { UserService } from '../services/user/user.service';
import { LanguageService } from '../services/language/language.service';

// Model
import { User } from '../models/user.model';

// Alerts
import Swal from 'sweetalert2';

// RxJS
import { Subscription } from 'rxjs';

// SEO
import { SeoService } from '../services/seo/seo.service';

/**
 * Declare plugins so then you can run it
 */
declare function init_plugins();

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  /**
   * The userEmail variable is declare
   * @param userEmail Variable to save the user’s email
   */
  userEmail: string;
  /**
   * The language variable is declare
   * @param language Variable to save the actual language
   */
  language: string;

  private subscription: Subscription;

  /**
   * Following variables declared
   * @param router Variable that manage the routes
   * @param userService Service that brings users data and functions
   * @param languageService Service that applies the selected language by the user
   */
  constructor(
    public router: Router,
    public userService: UserService,
    public languageService: LanguageService,
    private seoService: SeoService,
    private title: Title,
  ) {}

  /**
   * The plugins run
   */
  ngOnInit() {
    init_plugins();
    this.language = this.languageService.getLanguage();

    // SEO
    let desc: string;
    let t: string;
    if (this.language === 'es') {
      t = '¿Olvidaste la contraseña?';
      desc =
        'Herramientas para priorizar lo que realmente importa. Este portal está hecho por profesores para profesores.';
    } else {
      t = 'Forgot password?';
      desc =
        'Tools to help you prioritize what matters most. This portal is made by teachers for teachers to support you!';
    }
    this.title.setTitle(t);
    this.seoService.generateTags({
      title: t,
      description: desc,
      slug: 'forgot-password',
    });

    this.userEmail = localStorage.getItem('email') || '';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  recoverPassword(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const user = new User(
      form.value.first_name,
      form.value.last_name,
      form.value.userEmail,
      (form.value.language = this.languageService.getLanguage()),
      (form.value.login_type = 'LAB4U'),
      form.value.type,
      form.value.gender,
      form.value.password,
      form.value.biz_type,
      form.value.app,
      form.value.isGuest,
      form.value.institution,
      form.value.actual_deal,
      form.value.institution_id,
      form.value.usergroup,
      form.value.token,
      form.value.validated_acount,
      form.value.image,
      form.value.userId,
      form.value.user_type,
    );

    this.subscription = this.userService
      .PasswordRequestReset(user)
      .subscribe((resp) => {
        Swal.fire('OK', resp.message, 'success');
        this.router.navigate(['/sign-in']);
      });
  }
}
