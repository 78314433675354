import { Injectable, EventEmitter } from '@angular/core';

/**
 * Service that works with the modal-app
 */
@Injectable({
  providedIn: 'root',
})
export class ModalAppService {
  /**
   * The hide variable is declare
   * @param hide Variable to hide the modal
   */
  public hide = 'hide';

  /** Array with the data of each app button */
  appForAppModal: AppForAppModal[] = [];

  /**
   * @ignore
   */
  constructor() {
    // Define the app button data for front-end
    this.appForAppModal.push({
      appName: 'Lab4Physics',
      imageRoute: './assets/images/images/ic-l4p-btn.svg',
    });
    this.appForAppModal.push({
      appName: 'Lab4Chemistry',
      imageRoute: './assets/images/images/ic-l4c-btn.svg',
    });
    this.appForAppModal.push({
      appName: 'Lab4Biology',
      imageRoute: './assets/images/images/ic-l4b-btn.svg',
    });
  }

  /** Function that allows to hide the modal */
  hideModal() {
    this.hide = 'hide';
    return this.hide;
  }

  /** Function that allows to show the modal */
  showModal() {
    this.hide = '';
    return this.hide;
  }
}

class AppForAppModal {
  appName: string;
  imageRoute: string;
}
