import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivateRoutes } from 'src/app/config/config.routes';

// Service
import { ModalUserTypeService } from '../../components/modal-user-type/modal-user-type.service';

/**
 * Guard to force the user to choose an app
 */
@Injectable({
  providedIn: 'root',
})
export class IsLoginGuard {
  /**
   * Following variables declared
   * @param userService Service that bring user token
   * @param router Variable that manage the routes
   */
  constructor(
    public modalUserTypeService: ModalUserTypeService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}
  redirectUrl: any =
    this.activatedRoute.snapshot.queryParamMap.get('redirectUrl') ||
    PrivateRoutes.HOME;

  dashboard_url: string = PrivateRoutes.HOME;

  /**
   * Verify that the user has logged in
   */
  canActivate() {
    if (localStorage.getItem('token')) {
      this.modalUserTypeService.hideModal();
      /**
       * Verify that the user has a previous link
       */
      if (this.redirectUrl) {
        this.router.navigateByUrl(this.redirectUrl);
      } else {
        this.router.navigateByUrl(this.dashboard_url);
      }
      return true;
    } else {
      return true;
    }
  }
}
