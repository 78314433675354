import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

// RXJS
import { filter, map } from 'rxjs/operators';

// Translator
import { LanguageService } from '../../services/language/language.service';

// RxJS
import { Subscription } from 'rxjs';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';

/** Component to manage the breadcrumbs component
 */
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  /** The breadcrumbsTitle variable is declared
   * @param breadcrumbsTitle Variable to save every page tittle
   */
  breadcrumbsTitle: string;
  userId = this.userService.getId();

  hover_help: string;

  private subscription: Subscription;

  /** Following variables declared
   * @param router Variable that manage the routes
   * @param title Variable to obtain a tittle
   * @param meta Variable to create metaTag
   * @param languageService Service that works with the page language
   * @param modalPipedriveService Service that works Pipedrive modal
   */
  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private languageService: LanguageService,
    public mixpanelService: MixpanelService,
    public userService: UserService,
  ) {
    this.subscription = this.getDataRoute().subscribe((data) => {
      if (languageService.getLanguage() === 'es') {
        this.breadcrumbsTitle = data.tituloES;
      } else {
        this.breadcrumbsTitle = data.tituloEN;
      }

      this.title.setTitle(this.breadcrumbsTitle);

      const metaTag: MetaDefinition = {
        name: 'description',
        content: this.breadcrumbsTitle,
      };

      this.meta.updateTag(metaTag);
    });
  }

  ngOnInit() {
    //zendesk help
    if (this.languageService.getLanguage() === 'es') {
      this.hover_help = '¿Necesitas ayuda?';
    } else if (this.languageService.getLanguage() === 'en') {
      this.hover_help = 'Need help?';
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /** Function that returns to the actual route */
  getDataRoute() {
    return this.router.events.pipe(
      filter((event) => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      map((event: ActivationEnd) => event.snapshot.data),
    );
  }
}
