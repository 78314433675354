import { Component, OnInit } from '@angular/core';
import { PrivateRoutes } from 'src/app/config/config.routes';
import { scienceDTO } from 'src/app/models/science.model';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-apps-cards-list',
  templateUrl: './apps-cards-list.component.html',
  styleUrls: ['./apps-cards-list.component.css'],
})
export class AppsCardsListComponent implements OnInit {
  /**
   * @param language Variable to save the actual language
   */
  language: string;

  biologyTitle: string;
  physicsTitle: string;
  chemistryTitle: string;

  constructor(private languageService: LanguageService) {}
  sciences: scienceDTO[] = [
    {
      titleES: 'Física',
      titleEN: 'Physics',
      icon: '../../../assets/images/images/ic-l4p-btn.svg',
      url: PrivateRoutes.CATEGORIES,
      app: 'red',
    },
    {
      titleES: 'Química',
      titleEN: 'Chemistry',
      icon: '../../../assets/images/images/ic-l4c-btn.svg',
      url: PrivateRoutes.CATEGORIES,
      app: 'purple',
    },
    {
      titleES: 'Biología',
      titleEN: 'Biology',
      icon: '../../../assets/images/images/ic-l4b-btn.svg',
      url: PrivateRoutes.CATEGORIES,
      app: 'green',
    },
  ];
  ngOnInit(): void {
    this.language = this.languageService.getLanguage();

    this.biologyTitle = 'asdas';
  }
}
