import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { SidebarService, UserService } from 'src/app/services/service.index';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.css'],
})
export class PrimaryButtonComponent implements OnInit {
  /**
   * The color variable is declared
   * @param color Variable to keep colour
   */
  color: string;
  /**
   * The theme variable is declared
   * @param theme Variable to keep page theme
   */
  theme: string;

  @Input() linkTo: string = '';
  @Input() title: string = '';
  @Input() background: string = '';
  @Input() changeApp: string = '';

  userId = this.userService.getId();

  constructor(
    public settings: SettingsService,
    public sidebarService: SidebarService,
    public router: Router,
    public mixpanelService: MixpanelService,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    // this.mixpanelService.init(this.userId);
  }

  subjectSelected(subject_name) {
    this.mixpanelService.track(mixpanelEvent.HOME_SUBJECT_SELECTED, {
      source_name: 'LAB4UPORTAL',
      subject_name: subject_name,
    });
  }

  changeColor(theme: string) {
    const route = this.router.url.substring(0, 6);
    this.theme = theme;
    this.settings.applyTheme(this.theme);
    this.color = this.settings.getColor();
    if (this.color === 'red') {
      this.sidebarService.applyApp('LAB4PHYSICS');
      this.subjectSelected('LAB4PHYSICS');
    } else if (this.color === 'green') {
      this.sidebarService.applyApp('LAB4BIOLOGY');
      this.subjectSelected('LAB4BIOLOGY');
    } else {
      this.sidebarService.applyApp('LAB4CHEMISTRY');
      this.subjectSelected('LAB4CHEMISTRY');
    }
    // if (this.router.url.length > 25) {
    //   if (route === '/exper') {
    //     this.router.navigate(['/categories']);
    //   } else if (route === '/tool/') {
    //     this.router.navigate(['/tools']);
    //   } else {
    //     window.location.reload();
    //   }
    // } else {
    //   window.location.reload();
    // }
  }
}
