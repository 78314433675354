import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  private _globalData: {
    TOKEN: string;
    TokenSHA1: string;
    apiV1Key: string;
    apiV3Key: string;
    mailchimpApiKey: string;
    mailchimpDataCenter: string;
    mailchimpList: string;
    mixpanelProjectKey: string;
  };

  set globalData(data: any) {
    this._globalData = data;
  }

  get globalData(): any {
    return this._globalData;
  }
}
