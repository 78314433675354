/**
 * Variables of producions
 */
export const environment = {
  production: true,
  api_url: 'https://v1api.lab4u.co',

  api_v3_url: 'https://api.lab4u.co/v3',
  actual_env: 'production',
  secret_provider_url:
    'https://lab4u-portal-secrets-provider.azurewebsites.net/api/HttpTrigger',
  secret_provider_key:
    'oDrRNS4nZDiwgTlrLTrjEzvceAf7v09NzGzD_3-QBTtKAzFuz8NP5w==',
  //Planning AI
  API_BACKEND_URL: 'https://capstone-api.azurewebsites.net',
};
