<!-- cards-list-grid w-100 mt-4 -->
<div class="d-flex flex-wrap ">
    <div class="col-sm-12 col-lg-4 col-md-6 mt-md-3 mt-4"   *ngFor="let science of sciences">
        <app-apps-card
          [scienceIcon]="science.icon"
          [scienceTitle]="language==='es'? science.titleES : science.titleEN"
          [scienceUrl]="science.url"
          [science]="science.app"
        ></app-apps-card>
      </div>
</div>