import { Component, OnInit } from '@angular/core';

/**
 * Declare plugins so then you can run it
 */
declare function init_plugins();

/**
 * Component to manage 404 error
 */
@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.css']
})
export class NopagefoundComponent implements OnInit {

  /**
   * The year variable is declared
   * @param year Variable to save current year
   */
  year: number = new Date().getFullYear();

  /**
   * @ignore
   */
  constructor() { }

  /**
   * Run plugins to load the preloader
   */
  ngOnInit() {
    init_plugins();
  }

}
