import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Service
import { SidebarService } from '../shared/sidebar.service';
import { ModalAppService } from '../../components/modal-app/modal-app.service';
import { SettingsService } from '../settings/settings.service';

/**
 * Guard to not let the user to continue without signing up
 */
@Injectable({
  providedIn: 'root'
})
export class SelectAppGuard  {

  path: string;

  /**
   * Following variables declared
   * @param sidebarService Service that works with the app of the page
   * @param router Variable that manage the routes
   */
  constructor(
    public sidebarService: SidebarService,
    public modalAppService: ModalAppService,
    public router: Router,
    public settings: SettingsService,
    private activatedRoute: ActivatedRoute
  ) {}
  
  /**
   * Verify the user has a token which is created after the sign in
   */
  canActivate() {
    
    if ( this.sidebarService.getApp() === 'LAB4PHYSICS' || this.sidebarService.getApp() === 'LAB4CHEMISTRY' || this.sidebarService.getApp() === 'LAB4BIOLOGY') {
      return true;
    } else {
      /* it will trigger the select app modal in the header */
      return true

    }
  
   }
  

}
