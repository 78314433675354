<!-- Modal -->
<div class="fondo-negro animated fadeIn" [ngClass]="modalVideoService.hide">
  <div class="video-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <button class="close d-none d-lg-block" (click)="closeModal()" type="button" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body">
          <!-- Vimeo -->
          <!-- <iframe [src]="modalVideoService.video | safe: 'resourceUrl'" frameborder="0" allow="autoplay; fullscreen" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> -->
          <!-- YouTube -->
          <div class="embed-responsive embed-responsive-16by9" id="player">
            <iframe [src]="modalVideoService.video | safe: 'resourceUrl'" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>

        <div class="modal-footer d-block d-lg-none">
          <div class="d-flex w-100 h-100">
            <button (click)="closeModal()" type="button" class="btn btn-secondary ml-auto">{{ 'MODALS.CLOSE' | transloco
              }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
