import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// Model
import { User } from '../../models/user.model';

// Services
import { UserService } from '../../services/service.index';
import { SettingsService } from '../../services/settings/settings.service';
import { SidebarService } from '../../services/shared/sidebar.service';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { ClientSystemService } from 'src/app/services/api-lab4u/client_system.service';

// Translator
import { LanguageService } from '../../services/language/language.service';
import { TranslocoService } from '@ngneat/transloco';

// RxJS
import { Subscription } from 'rxjs';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';

/**
 * Component to manage the header component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  environment: string = environment.actual_env;

  /**
   * The user variable is declared
   * @param user Variable to save the connected user data
   */
  user: User;
  /**
   * The theme variable is declared
   * @param theme Variable to keep page theme
   */
  theme: string;
  /**
   * The color variable is declared
   * @param color Variable to keep colour
   */
  color: string;
  /**
   * The App variable is declared
   * @param App Variable to save the name on the active app
   */
  App = '';

  userRol = this.userService.getRole();
  userInstitution: string;

  userId = this.clientSystemService.getUserId();
  userEmail = this.userService.getUserEmail();
  /**
   * The language variable is declared
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * The loading variable is declared
   * @param loading Variable to show/hide the loading
   */
  loading = false;
  redirect: string;

  sidebarElements: any;

  private subscription: Subscription;

  /**
   * Following variables declared
   * @param userService Service that brings users data and functions
   * @param settings Service that works the page colour
   * @param languageService Service that works with the page language
   * @param translocoService Service that applies the selected language by the user
   * @param sidebarService Service that works with the sidebar and the app of the page
   */
  constructor(
    public clientSystemService: ClientSystemService,
    public userService: UserService,
    public settings: SettingsService,
    public languageService: LanguageService,
    private translocoService: TranslocoService,
    public sidebarService: SidebarService,
    public mixpanelService: MixpanelService,
    public router: Router,
  ) {
    this.selectLanguage();
    this.App = sidebarService.getApp();
    this.color = settings.getColor();
  }

  /**
   * Data is saved in the {@link ../injectables/UserService.html|userService.user} on the user variable
   *
   * Data is saved in the {@link ../injectables/LanguageService.html|languageService.getLanguage()} on the language variable
   *
   * Call the loadData() function to load all the necessary information
   */
  ngOnInit() {
    try {
      this.user = this.userService.user;
      this.language = this.languageService.getLanguage();
      this.userInstitution = this.userService.getInstitution();
      this.userService.showAppModal();
      this.loadData();
    } catch {
      return;
    }

    this.mixpanelService.init(
      this.user.biz_type.toUpperCase(),
      this.user.email.toUpperCase(),
      this.user.first_name.toUpperCase(),
      this.userId,
      this.user.institution.toUpperCase(),
      this.user.last_name.toUpperCase(),
      this.user.usergroup.toUpperCase(),
      this.user.user_type.toUpperCase(),
      this.user.deal.toUpperCase(),
    );

    const route = this.router.url.substring(0, 18);
    if (this.language === 'es') {
      this.sidebarElements = this.sidebarService.internalMenuES;
    } else {
      this.sidebarElements = this.sidebarService.internalMenuEN;
    }
    if (
      this.language === 'es' &&
      (route === '/general-documents' ||
        route === '/classrooms' ||
        route === '/home' ||
        route === '/topics')
    ) {
      this.sidebarElements = this.sidebarService.menuES;
    }
    if (
      this.language === 'en' &&
      (route === '/general-documents' ||
        route === '/classrooms' ||
        route === '/home' ||
        route === '/topics')
    ) {
      this.sidebarElements = this.sidebarService.menuEN;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Function to change language with Transloco
   * @param language Variable to save the actual language of languageService
   */
  selectLanguage(language: string = this.languageService.getLanguage()) {
    this.translocoService.setActiveLang(language);
  }

  /**
   * Function to change language localStorage
   * @param language Variable to save the actual language
   */
  changeLanguage(language: string) {
    const route = this.router.url.substring(0, 13);
    if (language === 'es' || language === 'en') {
      this.language = language;
      this.languageService.applyLanguage(this.language);
      this.mixpanelService.track(
        mixpanelEvent.TOPBAR_CHANGE_LANGUAGE_SELECTED,
        {
          source_name: 'LAB4UPORTAL',
          status: this.language.toUpperCase(),
        },
      );
      window.location.reload();
    }
  }

  /**
   * Function that loads user data
   */
  loadData() {
    if (
      this.App.toUpperCase() === 'LAB4PHYSICS' ||
      this.App.toUpperCase() === 'LAB4CHEMISTRY' ||
      this.App.toUpperCase() === 'LAB4BIOLOGY'
    ) {
      this.loading = false;
      this.subscription = this.userService
        .showUser(this.languageService.getLanguage(), this.App)
        .subscribe((resp: any) => {
          this.user = resp.user;
          this.loading = true;
        });
    } else {
      return;
    }
  }

  /**
   * Function to apply colour and app selected
   * @param theme Variable that brings the colour selected by the user
   */
  changeColor(theme: string) {
    if (
      this.App.toUpperCase() === 'LAB4PHYSICS' ||
      this.App.toUpperCase() === 'LAB4CHEMISTRY' ||
      this.App.toUpperCase() === 'LAB4BIOLOGY'
    ) {
      const route = this.router.url.substring(0, 6);
      this.theme = theme;
      this.settings.applyTheme(this.theme);
      this.color = this.settings.getColor();

      if (this.color === 'red') {
        this.sidebarService.applyApp('LAB4PHYSICS');
        this.mixpanelService.track(`header_change_app_LAB4PHYSICS`, {});
      } else if (this.color === 'purple') {
        this.sidebarService.applyApp('LAB4CHEMISTRY');
        this.mixpanelService.track(`header_change_app_LAB4CHEMISTRY`, {});
      } else {
        this.sidebarService.applyApp('LAB4BIOLOGY');
        this.mixpanelService.track(`header_change_app_LAB4BIOLOGY`, {});
      }
      if (this.router.url.length > 25) {
        if (route === '/exper') {
          this.router.navigate(['/categories']);
        } else if (route === '/tool/') {
          this.router.navigate(['/tools']);
        } else {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    } else {
      return;
    }
  }
  header_go_home() {
    this.mixpanelService.track('header_go_home', {
      'User role': this.userRol,
      "User's Institution": this.userInstitution,
    });
  }

  logOut() {
    this.userService.logout();

    this.mixpanelService.track('log_out', {
      'User role': this.userRol,
      "User's Institution": this.userInstitution,
    });
  }

  track() {
    this.mixpanelService.track('show_t_classroom_section', {
      "User's Institution": this.userInstitution,
    });
  }
}
