import { Injectable } from '@angular/core';
import { Country } from '../../models/countries.model';

@Injectable({
  providedIn: 'root'
})
export class SelectCountriesService {

  constructor() { }

  getCountries() {
    return [
      new Country(1, 'Afghanistan' ),
      new Country(2, 'Albania' ),
      new Country(3, 'Algeria' ),
      new Country(4, 'American Samoa' ),
      new Country(5, 'Andorra' ),
      new Country(6, 'Angola' ),
      new Country(7, 'Antigua and Barbuda' ),
      new Country(8, 'Argentina' ),
      new Country(9, 'Aruba' ),
      new Country(10, 'Australia' ),
      new Country(11, 'Austria' ),
      new Country(12, 'Azerbaijan' ),
      new Country(13, 'Bahamas' ),
      new Country(14, 'Bahrain' ),
      new Country(15, 'Bangladesh' ),
      new Country(16, 'Barbados' ),
      new Country(17, 'Belarus' ),
      new Country(18, 'Belgium' ),
      new Country(19, 'Belize' ),
      new Country(20, 'Benin' ),
      new Country(21, 'Bermuda' ),
      new Country(22, 'Bhutan' ),
      new Country(23, 'Bolivia' ),
      new Country(24, 'Bosnia and Herzegovina' ),
      new Country(25, 'Botswana' ),
      new Country(26, 'Brazil' ),
      new Country(27, 'British Virgin Islands' ),
      new Country(28, 'Brunei' ),
      new Country(29, 'Bulgaria' ),
      new Country(30, 'Burkina Faso' ),
      new Country(31, 'Burundi' ),
      new Country(32, 'AlCambodiabania' ),
      new Country(33, 'Cameroon' ),
      new Country(34, 'Canada' ),
      new Country(35, 'Canary Islands' ),
      new Country(36, 'Cape Verde' ),
      new Country(37, 'Cayman Islands' ),
      new Country(38, 'Central African Republic' ),
      new Country(39, 'Ceuta and Melilla' ),
      new Country(40, 'Chad' ),
      new Country(41, 'Chile' ),
      new Country(42, 'China' ),
      new Country(43, 'Colombia' ),
      new Country(44, 'Comoros' ),
      new Country(45, 'Congo [DRC]' ),
      new Country(46, 'Congo [Republic]' ),
      new Country(47, 'Costa Rica' ),
      new Country(48, 'Croatia' ),
      new Country(49, 'Cuba' ),
      new Country(50, 'Curaçao' ),
      new Country(51, 'Cyprus' ),
      new Country(52, 'Czech Republic' ),
      new Country(53, 'Côte d’Ivoire' ),
      new Country(54, 'Denmark' ),
      new Country(55, 'Djibouti' ),
      new Country(56, 'Dominica' ),
      new Country(57, 'Dominican Republic' ),
      new Country(58, 'Ecuador' ),
      new Country(59, 'Egypt' ),
      new Country(60, 'El Salvador' ),
      new Country(61, 'Equatorial Guinea' ),
      new Country(62, 'Eritrea' ),
      new Country(63, 'Estonia' ),
      new Country(64, 'Ethiopia' ),
      new Country(65, 'Europe' ),
      new Country(66, 'Faroe Islands' ),
      new Country(67, 'Fiji' ),
      new Country(68, 'Finland' ),
      new Country(69, 'France' ),
      new Country(70, 'French Guiana' ),
      new Country(71, 'French Polynesia' ),
      new Country(72, 'Gabon' ),
      new Country(73, 'Gambia' ),
      new Country(74, 'Georgia' ),
      new Country(75, 'Germany' ),
      new Country(76, 'Ghana' ),
      new Country(77, 'Gibraltar' ),
      new Country(78, 'Greece' ),
      new Country(79, 'Greenland' ),
      new Country(80, 'Grenada' ),
      new Country(81, 'Guadeloupe' ),
      new Country(82, 'Guam' ),
      new Country(83, 'Guatemala' ),
      new Country(84, 'Guernsey' ),
      new Country(85, 'Guinea' ),
      new Country(86, 'Guinea-Bissau' ),
      new Country(87, 'Guyana' ),
      new Country(88, 'Haiti' ),
      new Country(89, 'Honduras' ),
      new Country(90, 'Hong Kong' ),
      new Country(91, 'Hungary' ),
      new Country(92, 'Iceland' ),
      new Country(93, 'India' ),
      new Country(94, 'Indonesia' ),
      new Country(95, 'Iran' ),
      new Country(96, 'Iraq' ),
      new Country(97, 'Isle of Man' ),
      new Country(98, 'Israel' ),
      new Country(99, 'Italy' ),
      new Country(100, 'Jamaica' ),
      new Country(101, 'Japan' ),
      new Country(102, 'Jersey' ),
      new Country(103, 'Jordan' ),
      new Country(104, 'Kazakhstan' ),
      new Country(105, 'Kenya' ),
      new Country(106, 'Kiribati' ),
      new Country(107, 'Kuwait' ),
      new Country(108, 'Kyrgyzstan' ),
      new Country(109, 'Laos' ),
      new Country(110, 'Latin America' ),
      new Country(111, 'Latvia' ),
      new Country(112, 'Lebanon' ),
      new Country(113, 'Lesotho' ),
      new Country(114, 'Liberia' ),
      new Country(115, 'Libya' ),
      new Country(116, 'Liechtenstein' ),
      new Country(117, 'Lithuania' ),
      new Country(118, 'Luxembourg' ),
      new Country(119, 'Macau' ),
      new Country(120, 'Macedonia [FYROM]' ),
      new Country(121, 'Madagascar' ),
      new Country(122, 'Malawi' ),
      new Country(122, 'Malaysia' ),
      new Country(123, 'Mali' ),
      new Country(124, 'Malta' ),
      new Country(125, 'Marshall Islands' ),
      new Country(126, 'Martinique' ),
      new Country(127, 'Mauritania' ),
      new Country(128, 'Mauritius' ),
      new Country(129, 'Mayotte' ),
      new Country(130, 'Mexico' ),
      new Country(131, 'Micronesia' ),
      new Country(132, 'Moldova' ),
      new Country(133, 'Monaco' ),
      new Country(134, 'Mongolia' ),
      new Country(135, 'Montenegro' ),
      new Country(136, 'Morocco' ),
      new Country(137, 'Mozambique' ),
      new Country(138, 'Myanmar [Burma]' ),
      new Country(139, 'Namibia' ),
      new Country(140, 'Nepal' ),
      new Country(141, 'Netherlands' ),
      new Country(142, 'New Caledonia' ),
      new Country(143, 'New Zealand' ),
      new Country(144, 'Nicaragua' ),
      new Country(145, 'Niger' ),
      new Country(146, 'Nigeria' ),
      new Country(147, 'North Korea' ),
      new Country(148, 'Northern Mariana Islands' ),
      new Country(149, 'Norway' ),
      new Country(150, 'Oman' ),
      new Country(151, 'Pakistan' ),
      new Country(152, 'Palau' ),
      new Country(153, 'Palestine' ),
      new Country(154, 'Panama' ),
      new Country(155, 'Papua New Guinea' ),
      new Country(156, 'Paraguay' ),
      new Country(157, 'Peru' ),
      new Country(158, 'Philippines' ),
      new Country(159, 'Poland' ),
      new Country(160, 'Portugal' ),
      new Country(161, 'Puerto Rico' ),
      new Country(162, 'Qatar' ),
      new Country(163, 'Romania' ),
      new Country(164, 'Russia' ),
      new Country(165, 'Rwanda' ),
      new Country(166, 'Réunion' ),
      new Country(167, 'Saint Barthélemy' ),
      new Country(168, 'Saint Kitts and Nevis' ),
      new Country(169, 'Saint Lucia' ),
      new Country(170, 'Saint Martin' ),
      new Country(171, 'Saint Vincent and the Grenadines' ),
      new Country(172, 'Samoa' ),
      new Country(173, 'San Marino' ),
      new Country(174, 'Saudi Arabia' ),
      new Country(175, 'Senegal' ),
      new Country(176, 'Serbia' ),
      new Country(177, 'Seychelles' ),
      new Country(178, 'Sierra Leone' ),
      new Country(179, 'Singapore' ),
      new Country(180, 'Sint Maarten' ),
      new Country(181, 'Slovakia' ),
      new Country(182, 'Slovenia' ),
      new Country(183, 'Solomon Islands' ),
      new Country(184, 'South Africa' ),
      new Country(185, 'South Korea' ),
      new Country(186, 'South Sudan' ),
      new Country(187, 'Spain' ),
      new Country(188, 'Sri Lanka' ),
      new Country(189, 'Sudan' ),
      new Country(190, 'Suriname' ),
      new Country(191, 'Swaziland' ),
      new Country(192, 'Switzerland' ),
      new Country(193, 'Syria' ),
      new Country(194, 'São Tomé and Príncipe' ),
      new Country(195, 'Taiwan' ),
      new Country(196, 'Tanzania' ),
      new Country(197, 'Thailand' ),
      new Country(198, 'Timor-Leste' ),
      new Country(199, 'Togo' ),
      new Country(200, 'Tonga' ),
      new Country(201, 'Trinidad and Tobago' ),
      new Country(202, 'Tunisia' ),
      new Country(203, 'Turkey' ),
      new Country(204, 'Turks and Caicos Islands' ),
      new Country(205, 'U.S. Outlying Islands' ),
      new Country(206, 'U.S. Virgin Islands' ),
      new Country(207, 'Uganda' ),
      new Country(208, 'Ukraine' ),
      new Country(209, 'United Arab Emirates' ),
      new Country(210, 'United Kingdom' ),
      new Country(211, 'United States' ),
      new Country(212, 'Uruguay' ),
      new Country(213, 'Uzbekistan' ),
      new Country(214, 'Vanuatu' ),
      new Country(215, 'Venezuela' ),
      new Country(216, 'Vietnam' ),
      new Country(217, 'Western Sahara' ),
      new Country(218, 'Yemen' ),
      new Country(219, 'Zambia' ),
      new Country(220, 'Zimbabwe' ),
      new Country(221, 'Åland Islands' )
    ];
  }
}
