import { Injectable } from '@angular/core';
import { ApiCallBase } from '../apiCallBase';
import { LanguageService } from '../../language/language.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_V3_URL } from 'src/app/config/config';
import { catchError } from 'rxjs/operators';
import { GlobalStateService } from 'src/app/global-state.service';

@Injectable({
  providedIn: 'root',
})
export class ManageAccountService extends ApiCallBase {
  correctedLanguage: string = 'EN-US';
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
    this.correctedLanguage = this.language === 'es' ? 'ES-LA' : 'EN-US';
  }

  accountDataDeletionRequest(body: { email: string; language: string }) {
    body.language = this.correctedLanguage;
    let url = `${API_V3_URL}/auth/account-data-deletion-request`;
    return this.http.post(url, body, this.getHttpOptionsV3()).pipe(
      catchError((error) => {
        throw error;
      }),
    );
  }

  accountDataDeletionComplete(payload: string) {
    let url = `${API_V3_URL}/auth/account-data-deletion-complete`;
    return this.http.post(url, { hash: payload }, this.getHttpOptionsV3()).pipe(
      catchError((error) => {
        throw error;
      }),
    );
  }
}
