import { Injectable, EventEmitter } from '@angular/core';

/**
 * Service that works with the modal-pdf
 */
@Injectable({
  providedIn: 'root',
})
export class ModalPdfService {
  /**
   * The hide variable is declare
   * @param hide Variable to hide the modal
   */
  public hide = 'hide';
  /**
   * The notification variable is declare
   * @param notification Variable to send notifications
   */
  public notification = new EventEmitter<any>();

  /**
   * The urlPdf variable is declare
   * @param urlPdf Variable to save the URL of PDF
   */
  public urlPdf = '';

  /**
   * @ignore
   */
  constructor() {}

  /**
   * Function that allows to hide the modal
   */
  hideModal() {
    this.hide = 'hide';
    this.urlPdf = '';
    return this.hide;
  }

  /**
   * Function that allows to show the modal
   */
  showModal(url: string) {
    this.urlPdf = url;
    this.hide = '';
    return this.urlPdf;
  }
}
