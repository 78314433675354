import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../services/language/language.service';
import { SidebarService } from '../services/shared/sidebar.service';

/**
 * Se declaran los plugins para luego inicializarlos
 */
declare function init_plugins();

/**
 * Componente para cargar las pages
 */
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [],
})
export class PagesComponent implements OnInit {
  /**
   * The language variable is declared
   * @param language Variable to save the actual language
   */
  language: string;
  /** Items of the Menu */
  sidebarElements: any;

  /**
   * @ignore
   */
  constructor(
    public sidebarService: SidebarService,
    public languageService: LanguageService,
    public router: Router,
  ) {
    this.language = languageService.getLanguage();
  }

  /**
   * Se inicializan los plugins para cargar bien el preloader
   */
  ngOnInit() {
    const route = this.router.url.substring(0, 18);
    init_plugins();
    if (this.language === 'es') {
      this.sidebarElements = this.sidebarService.internalMenuES;
    } else {
      this.sidebarElements = this.sidebarService.internalMenuEN;
    }
    if (
      this.language === 'es' &&
      (route === '/general-documents' ||
        route === '/classrooms' ||
        route === '/topics')
    ) {
      this.sidebarElements = this.sidebarService.menuES;
    }
    if (
      this.language === 'en' &&
      (route === '/general-documents' ||
        route === '/classrooms' ||
        route === '/topics')
    ) {
      this.sidebarElements = this.sidebarService.menuEN;
    }
  }
}
