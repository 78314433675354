<div *transloco="let text; read: 'DELETE-ACCOUNT'">
  <!-- TOP NAVBAR -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand">
    <!-- LOGO -->
    <div class="">
      <a class="navbar-brand" routerLink="/sign-in">
        <div class="navbar-brand-wrap row">
          <!-- IMAGE -->
          <div
            class="resp-img-container d-block d-sm-none my-auto topbar-icon mr-3"
          >
            <img
              class="logo-image"
              src="./assets/images/gallery/portal-logo.png"
              alt="homepage"
            />
          </div>

          <!-- TEXT -->
          <div class="resp-img-container my-auto d-none d-sm-block">
            <img
              class="logo-text-image"
              src="./assets/images/gallery/portal-ext-logo.png"
              alt="homepage"
              height="100"
            />
          </div>
        </div>
      </a>
    </div>

    <!-- MENU ELEMENTS -->
    <div class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <!-- LANGUAGE SELECTORS -->
        <li class="nav-item language flat-button row">
          <div class="my-auto">
            <a (click)="changeLanguage('en')" class="change-language pointer"
              >EN
            </a>
            <span class="cursor separation">|</span>
            <a (click)="changeLanguage('es')" class="change-language pointer">
              ES</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<div class="p-4 p-md-5 m-md-5" *ngIf="!submitted && errorCode != 420 ">
  <h1 class="delete-account-title">{{ text('TITLE') }}</h1>
  <p class="pb-3">
    {{ text('DESCRIPTION') }}
  </p>
  <div
    class="delete-account-form d-flex flex-column"
    [formGroup]="deleteAccountForm"
  >
    <!-- Bind del FormGroup deleteAccountForm -->
    <p class="delete-account-form-label">Email</p>
    <input
      class="delete-account-form-input"
      [ngClass]="{ 'invalid-input': isInvalidEmail() || errorCode === 404 || errorCode === 420 || errorCode === 500 }"
      formControlName="email"
      type="email"
      placeholder="example@gmail.com"
    />
    <!-- validation messages -->
    <div *ngIf="isInvalidEmail()" class="invalid-text">{{ text('NOT-VALID') }}</div>
    <div *ngIf="errorCode === 404" class="invalid-text">
      {{ text('ERROR-404') }}
    </div>
    <div *ngIf="errorCode === 500" class="invalid-text">
      {{ text('ERROR-500') }}
    </div>
    <mat-checkbox formControlName="checkboxStatus" class="my-2" color="primary">
      {{ text('CHECKBOX') }}
    </mat-checkbox>
    <button
      [disabled]="!buttonStatus()"
      [ngClass]="{ 'delete-account-form-button-disabled': !buttonStatus() }"
      class="css-button-fully-rounded delete-account-form-button"
      (click)="sendAccountDataDeletionRequest()"
    >
      {{ text('BUTTON') }}
    </button>
  </div>
</div>
<div *ngIf="submitted" class="p-md-5">
  <h1 class="delete-account-title"> {{text('SUBMITTED-TITLE')}} </h1>
  <p>
    {{ text('SUBMITTED-SUBTITLE') }}
  </p>
</div>
<div *ngIf="errorCode === 420" class="p-md-5">
  <h1 class="delete-account-title">{{ text('ERROR-420-TITLE') }}</h1>
  <p>
    {{ text('ERROR-420-SUBTITLE') }}
  </p>
</div>

</div>