import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Service to manage the theme of the page
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  /**
   * The variable settings are declared from the interface Settings
   * @param settings Variable to save the theme URL and the same theme
   */
  settings: Settings = {
    themeUrl: 'assets/css/colors/default.css',
    theme: 'default'
  };

  /**
   * The variable document is declared, and the page theme is load
   * @param document Allows to change an attribute from the html
   */
  constructor( @Inject(DOCUMENT) private document ) {
    this.loadSettings();
  }

  /**
   * Function that save the settings at the localStorage
   */
  saveSettings() {
    localStorage.setItem('settings', JSON.stringify( this.settings )  );
  }

  /**
   * Function that loads the information from the localStorage
   */
  loadSettings() {

    if ( localStorage.getItem('settings') ) {
      this.settings = JSON.parse( localStorage.getItem('settings') );
      this.applyTheme( this.settings.theme );

    } else {
      this.applyTheme( this.settings.theme );
    }

  }

  /**
   * Function that applies the theme selected by the user
   * @param theme Variable that brings the theme to apply
   */
  applyTheme( theme: string ) {

    const url = `assets/css/colors/${ theme }.css`;
    this.document.getElementById('tema').setAttribute('href', url );

    this.settings.theme = theme;
    this.settings.themeUrl = url;

    this.saveSettings();

  }

  /**
   * Function that return the colour
   */
  getColor() {
    if ( JSON.parse( localStorage.getItem('settings') ) === null ) {
      return;
    } else {
      const color = JSON.parse( localStorage.getItem('settings') );
      return color.theme;
    }
   }

}

/**
 * An interface is declared in order to always conserve the same model in the Settings variable
 */
interface Settings {
  /**
   * The theme URL variable is declared to save the URL theme
   */
  themeUrl: string;
  /**
   * The variable theme is declared to save the name of the theme
   */
  theme: string;
}
