import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { newsItem } from 'src/app/models/news.model';
import { NewsSectionService } from 'src/app/services/api-lab4u/news-section.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { PrivateRoutes } from 'src/app/config/config.routes';
import { ChangeAppService } from 'src/app/services/shared/change-app.service';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  language: string;

  toggleNews: boolean = true;
  @Output() toggleNewsStatus = new EventEmitter<boolean>();
  expProfile: string;

  newsCount: number = 0;

  userId = this.userService.getId();

  constructor(
    public newsSectionService: NewsSectionService,
    private languageService: LanguageService,
    public changeAppService: ChangeAppService,
    public mixpanelService: MixpanelService,
    public userService: UserService,
  ) {}

  newsList: newsItem[] = [
    {
      id: '',
      title: '',
      thumbnail_url: '',
      type: '',
      experiment: {
        exp_id: '',
        app_id: '',
        app_name: '',
      },
      link_url: '',
    },
  ];

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.expProfile = PrivateRoutes.EXP_PROFILE;
    this.getNews();
  }
  //TODO 1: Add method to unsubscribe
  getNews() {
    this.newsSectionService.getNews(this.language).subscribe((res: any) => {
      this.newsList = res.response.news;
      this.newsList.forEach((element) => {
        if (element) {
          this.newsCount++;
        }
      });
    });
  }

  applyApp(
    app: string,
    experiment_id: string,
    news_name: string,
    news_id: string,
  ) {
    this.changeAppService.applyApp(app.toUpperCase());
    this.mixpanelService.track(mixpanelEvent.NEWS_EXPERIMENT_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
      experiment_id: experiment_id,
      experiment_name: news_name,
      news_id: news_id,
    });
  }

  linkSelected(news_url: string, news_id: string) {
    this.mixpanelService.track(mixpanelEvent.NEWS_LINK_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
      news_url,
      news_id,
    });
  }

  toggleShowNews = () => {
    this.toggleNews = !this.toggleNews;
    this.toggleNewsStatus.emit(this.toggleNews);
  };
}
