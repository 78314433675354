<!-- Modal -->
<div class="fondo-negro animated fadeIn" [ngClass]="modalPaywallService.hide">
  <div class="paywall-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <button class="close d-none d-lg-block" (click)="closeModal()" type="button" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body">

          <div class="row">
            <div class="resp-img-container mx-auto crown">
              <img class="" src="./assets/images/images/premium-ic-no-shadow@2x.png" alt="crown">
            </div>
          </div>

          <h2 class="modal-title mt-2 mt-md-4">
            {{ 'MODAL-PAYWALL.TITLE' | transloco }}
          </h2>

          <p class="modal-description mt-2 mt-md-3">
            {{ 'MODAL-PAYWALL.DESCRIPTION' | transloco }}
          </p>


          <div *ngIf="language === 'es'" class="mt-4">
            <a href="https://lab4u.co/es/precios/" target="_blank" class="btn btn-success btn-enter btn-cards">
              {{'MODAL-PAYWALL.BUTTON' | transloco }}
            </a>
          </div>

          <div *ngIf="language !== 'es'" class="mt-4">
            <a href="https://lab4u.co/en/pricing/" target="_blank" class="btn btn-success btn-enter btn-cards">
              {{'MODAL-PAYWALL.BUTTON' | transloco }}
            </a>
          </div>

        </div>

        <div class="modal-footer d-block d-lg-none">
          <div class="d-flex w-100 h-100">
            <button (click)="closeModal()" type="button" class="btn btn-secondary ml-auto">
              {{ 'MODALS.CLOSE' | transloco }}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
