import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

//* ChatMessage type to be used in both the Component and API calls
export type ChatMessage = {
  content: string;
  role: 'user' | 'assistant';
};

//* Global declaration for TypeScript to allow the use of window.MathJax
declare global {
  interface Window {
    MathJax: {
      typeset: () => void;
      tex?: any;
      svg?: any;
    };
  }
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
})
export class ChatbotComponent
  implements AfterViewChecked, OnChanges, OnDestroy, OnInit
{
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;
  @Input() avatarPath: string = '';
  @Input() initialMessage: string = 'Enviar';
  @Input() disabledMessage: string = 'No puedes enviar mensajes';
  @Input() assistantResponse: string = '';
  @Input() disableUserInput: boolean;
  @Output() messageHistory = new EventEmitter<ChatMessage[]>();
  messages: ChatMessage[] = [];
  userMessage: string = '';

  ngOnInit(): void {
    this.messageHistory.emit(this.messages);
    this.messages.push({ content: '...', role: 'assistant' });
  }

  ngAfterViewChecked() {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assistantResponse']) {
      if (this.assistantResponse !== '')
        this.messages[this.messages.length - 1] = {
          content: this.assistantResponse,
          role: 'assistant',
        };
    }
  }

  ngOnDestroy(): void {
    this.clear();
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
  }

  sendMessage(event?: KeyboardEvent): void {
    if (event && event.key !== 'Enter') return;
    if (event) event.preventDefault();
    const content = this.userMessage.trim();
    if (content) {
      this.messages.push({ content, role: 'user' });
      this.userMessage = '';
      const textarea = document.querySelector('textarea');
      if (textarea) textarea.style.height = 'auto';
      setTimeout(() => this.scrollToBottom(), 0);
      this.messageHistory.emit(this.messages);
      setTimeout(() => {}, 500);
      this.messages.push({ content: '...', role: 'assistant' });
    }
  }

  clear(): void {
    this.messages = [];
    this.userMessage = '';
  }

  scrollToBottom(): void {
    this.messagesContainer.nativeElement.scrollTop =
      this.messagesContainer.nativeElement.scrollHeight;
  }
}
