<!-- Modal -->
<div
  class="{{ backgroundClass }} animated fadeIn"
  [ngClass]="modalAppService.hide"
>
  <div class="app-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content mx-3 mx-md-0">
        <div class="modal-body">
          <div class="modal-title mt-3 mx-3 mt-sm-0 mx-sm-0">
            <h5 class="welcome-subtitle general-title cursor">
              {{ 'MODAL-APP.TITLE' | transloco }}
            </h5>
          </div>

          <div class="app-cards-container row mt-4 mt-sn-5">
            <!-- appForAppModal is Defined in modal-app.service.ts -->
            <div
              class="col-12 col-sm-4 mb-3 mb-0"
              *ngFor="let modalApp of modalAppService.appForAppModal"
            >
              <div class="app-card mx-auto">
                <a (click)="applyApp(modalApp.appName)" class="">
                  <div class="card-img-container row">
                    <img
                      [src]="modalApp.imageRoute"
                      class="mx-auto w-[305px] h-auto"
                      alt="{{ modalApp.appName }}"
                    />
                  </div>
                </a>

                <div class="app-title mt-2">
                  <p class="subtitle">
                    {{ modalApp.appName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
