<!-- TOP NAVBAR -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand">
    <!-- LOGO -->
    <div class="">
      <a class="navbar-brand" routerLink="/sign-in">
        <div class="navbar-brand-wrap row">
          <!-- IMAGE -->
          <div
            class="resp-img-container d-block d-sm-none my-auto topbar-icon mr-3"
          >
            <img
              class="logo-image"
              src="./assets/images/gallery/portal-logo.png"
              alt="homepage"
            />
          </div>

          <!-- TEXT -->
          <div class="resp-img-container my-auto d-none d-sm-block">
            <img
              class="logo-text-image"
              src="./assets/images/gallery/portal-ext-logo.png"
              alt="homepage"
              height="100"
            />
          </div>
        </div>
      </a>
    </div>

    <!-- MENU ELEMENTS -->
    <div class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <!-- SING-UP CTA -->
        <li class="nav-item dropdown row">
          <a
            routerLink="/sign-up"
            class="register dropdown-item flat-button pointer p-0 my-auto"
          >
            {{ 'HEADER.SIGN-UP' | transloco }}
          </a>
        </li>

        <!-- SEPARATOR -->
        <li class="nav-item dropdown mx-2" style="font-size: 30px">
          <label class="separation"> | </label>
        </li>

        <!-- LANGUAGE SELECTORS -->
        <li class="nav-item language flat-button row">
          <div class="my-auto">
            <a (click)="changeLanguage('en')" class="change-language pointer"
              >EN
            </a>
            <span class="cursor separation">|</span>
            <a (click)="changeLanguage('es')" class="change-language pointer">
              ES</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<!-- MAIN WRAP -->
<section class="col-12 col-sm-11 col-lg-10 row mx-auto px-4 px-md-5 login-wrap">
  <div class="w-100 row mx-0 my-auto login-grid">
    <!-- MAIN IMAGE -->
    <div class="d-none d-lg-flex pr-4">
      <div class="resp-img-container row mx-auto main-image">
        <img
          class="m-auto"
          src="./assets/images/images/login-background.png"
          alt="homepage"
        />
      </div>
    </div>

    <!-- FORM -->
    <div class="d-flex">
      <div class="w-100 form-wrap mx-auto">
        <!-- SIGN IN FORM -->
        <form
          ngNativeValidate
          #f="ngForm"
          class="form-horizontal form-material"
          (ngSubmit)="signIn(f)"
        >
          <div id="wrap">
            <span class="login-title general-title cursor">{{
              'SIGN-IN.TITLE' | transloco
            }}</span>
            <div class="form-group m-t-40">
              <div class="col-xs-12">
                <span class="field-title cursor">{{
                  'SIGN-IN.EMAIL' | transloco
                }}</span>
                <input
                  [ngModel]="userEmail"
                  name="userEmail"
                  userEmail="email"
                  class="form-control"
                  type="email"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-12">
                <span class="field-title cursor">{{
                  'SIGN-IN.PASSWORD' | transloco
                }}</span>
                <input
                  ngModel
                  name="userPass"
                  class="form-control"
                  type="password"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <div class="checkbox checkbox-primary pull-left p-t-0">
                  <input
                    [(ngModel)]="rememberUser"
                    name="rememberUser"
                    id="checkbox-signup"
                    type="checkbox"
                  />
                  <label for="checkbox-signup" class="field-title">
                    {{ 'SIGN-IN.REMEMBER-ME' | transloco }}
                  </label>
                </div>
                <a
                  routerLink="/forgot-password"
                  id="to-recover"
                  class="flat-button pull-right"
                >
                  {{ 'SIGN-IN.FORGOT-PSW' | transloco }}</a
                >
              </div>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <button
                  class="btn btn-lg btn-block call-to-action btn-rounded btn-cards"
                  type="submit"
                >
                  {{ 'SIGN-IN.BUTTON' | transloco }}
                </button>
              </div>
            </div>
          </div>
        </form>

        <!-- SING IN WITH GOOGLE -->
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center cursor social-wrap field-title">
            {{ 'SIGN-IN.SOCIAL-SIGN-IN' | transloco }}
            <br /><br />
            <div class="d-flex justify-content-center text-center">
              <asl-google-signin-button type="standard" theme="filled_blue" shape="pill" size='large' logo_alignment="left" width="250"></asl-google-signin-button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
