import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language/language.service';
import { Router, NavigationEnd } from '@angular/router';

// RxJs
import { filter } from 'rxjs/operators';
import { GlobalStateService } from './global-state.service';

// Google Analytics
declare var gtag;

/**
 * Angular component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  /**
   * The language variable is declared
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * The title variable is declared
   * @param title Variable to save web tittle
   */
  title = 'Teacher Portal';

  /**
   * The languageService variable is declared
   * @param languageService Service that works with the page language
   */
  constructor(
    private languageService: LanguageService,
    private router: Router,
    private globalStateService: GlobalStateService,
  ) {
    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );
    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-EN2X1MJ7MB', {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  /**
   * Called of the function that detects the browser language
   */
  ngOnInit() {
    this.automaticLanguageDetection();
  }

  /**
   * Function that returns the browser language
   */
  automaticLanguageDetection() {
    if (!this.languageService.existLanguage()) {
      const x = navigator.language;
      this.language = this.replaceStr(x, '[0-9 -]', '');
      this.language = this.language.slice(0, 2);
      this.languageService.saveLanguage(this.language);
      return this.language;
    } else {
      return 'en';
    }
  }

  /**
   * Function that allows to clean the language string and leave it just on the code of two variables
   * @param value Variable that brings the browser language
   * @param regexValue Characters you want to clean from value
   * @param replaceValue Value you want to give to the characters you may change
   */
  replaceStr(value: string, regexValue: string, replaceValue: string): any {
    const regex = new RegExp(regexValue, 'g');
    return value.replace(regex, replaceValue);
  }
}
