<!-- Modal -->
<div class="fondo-negro animated fadeIn" [ngClass]="modalComingSoonService.hide">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <img class="coming-soon" src="./assets/images/images/coming-soon.png" alt="Coming Soon">
                <h2 class="modal-title">{{ 'MODAL-COMING-SOON.TITLE' | transloco }}</h2>
                <p class="modal-description">{{ 'MODAL-COMING-SOON.DESCRIPTION' | transloco }}</p>
                <a (click)="closeModal()" class="btn btn-success btn-enter btn-cards">{{ 'MODAL-COMING-SOON.BUTTON' | transloco }}</a>
            </div>
            <div class="modal-footer">
                <button (click)="closeModal()" type="button" class="btn btn-secondary btn-responsive">{{ 'MODALS.CLOSE' | transloco }}</button>
            </div>
        </div>
    </div>
</div>