<ng-container *ngIf="displayView">
  <img
    *ngIf="chatStarted"
    class="return-button"
    (click)="resetChat()"
    src="../../assets/images/tutoria/arrow-left.svg"
  />
  <div class="tutoria-banner-container">
    <div class="tutoria-banner">
      <img src="../../assets/images/tutoria/tutoria-logo.svg" />
    </div>
    <div *ngIf="chatStarted" class="tutoria-ai-reminder">
      <div class="tutoria-ai-reminder-icon">
        <img src="../../assets/images/tutoria/red-pin-icon.svg" />
      </div>
      <span
        >Recuerda que soy una IA y puedo cometer errores. Comprueba la
        información entregada.</span
      >
    </div>
  </div>
  <app-chatbot
    *ngIf="chatStarted"
    avatarPath="../../assets/images/tutoria/cami-avatar.svg"
    initialMessage="Envía un mensaje a Cami"
    disabledMessage="Espera a que Cami te responda"
    (messageHistory)="sendChatMessageHistory($event)"
    [assistantResponse]="assistantResponse"
    [disableUserInput]="disableUserInput"
  >
  </app-chatbot>
  <div *ngIf="!chatStarted" class="tutoria-container">
    <div class="avatar-message-container">
      <img src="../../assets/images/tutoria/cami-avatar.svg" />
      <div class="avatar-message">
        <p>
          ¡Hola! Mi nombre es Cami. Estoy aquí para ayudarte a estudiar y
          practicar para tus pruebas. Recuerda que soy una IA y puedo cometer
          errores. ¡Comencemos!
        </p>
      </div>
    </div>
    <div class="study-options-container">
      <div
        *ngFor="let studyOption of studyOptions"
        class="study-option"
        (click)="selectStudyOption(studyOption)"
      >
        <div class="icon-container">
          <img [src]="studyOption.iconPath" />
        </div>
        <span class="study-option-text"
          >Quiero estudiar {{ studyOption.name }}</span
        >
      </div>
    </div>
  </div>
</ng-container>
