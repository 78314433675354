import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { ClientSystemService } from '../api-lab4u/client_system.service';
import { GlobalStateService } from 'src/app/global-state.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  env_project_key: string =
    this.globalStateService.globalData?.mixpanelProjectKey;
  init(
    biz_type: string,
    email: string,
    firstName: string,
    userId: string,
    institution: string,
    lastName: string,
    user_group: string,
    user_type: string,
    deal: string,
  ): void {
    //project token
    mixpanel.init(this.env_project_key, { debug: false, ignore_dnt: true }); //ignores the don't track option if activated in the user's browser
    mixpanel.identify(email);
    mixpanel.people.set({
      id: userId,
      email: email,
      first_name: firstName,
      last_name: lastName,
      institution: institution,
      user_group: user_group,
      user_type: user_type,
      biz_type: biz_type,
      deal: deal,
    });
  }

  webviewInit(email: string): void {
    mixpanel.init(this.env_project_key, { debug: false, ignore_dnt: true });
    mixpanel.reset();
    mixpanel.identify(email);
  }

  constructor(
    public clientSystemService: ClientSystemService,
    public globalStateService: GlobalStateService,
  ) {}
  userId = this.clientSystemService.getUserId();
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  reset() {
    mixpanel.reset();
  }
}
