import { Component, OnInit } from '@angular/core';

// Modal service
import { ModalVideoService } from './modal-video.service';

/**
 * Component to show YouTube video
 */
@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.css']
})
export class ModalVideoComponent implements OnInit {

  /**
   * The modalVideoService variable is declare
   * @param modalVideoService Variable to show/hide modal
   */
  constructor(
    public modalVideoService: ModalVideoService
    ) {}

  /**
   * @ignore
   */
  ngOnInit() {}

  /**
   * Function that allows to close the modal using the service of the modal
   */
  closeModal() {
    this.modalVideoService.hideModal();
    return true;
  }

}
