import { Component, OnInit } from '@angular/core';

// Modal service
import { ModalPdfService } from './modal-pdf.service';

/**
 * Component for the user to view the pdf
 */
@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.css']
})
export class ModalPdfComponent implements OnInit {

  /**
   * The modalVideoService variable is declare
   * @param modalPdfService Variable to show/hide modal
   */
  constructor(
    public modalPdfService: ModalPdfService
  ) {
  }

  /**
   * @ignore
   */
  ngOnInit() {}


  /**
   * Function that allows to close the modal using the service of the modal
   */
  closeModal() {
    this.modalPdfService.hideModal();
    return true;
  }

}
