<i
  class="fas fa-bars d-block d-md-none burguer-button"
  (click)="toggleShowSidebar()"
></i>
<nav
  class="nh-navbar d-md-block"
  [ngClass]="{ 'd-none': !toggleSidebar, 'nh-navbar-open': toggleSidebar }"
>
  <div [ngClass]="{ 'd-none': !toggleSidebar }" class="d-md-none">
    <img
      class="close-button"
      src="../../../assets/images/sidebar/exit-ic.svg"
      alt="close menu"
      (click)="toggleShowSidebar()"
    />
  </div>
  <ul class="nh-navbar-nav" *ngFor="let menu of sidebarElements">
    <li class="w-100 align-content-center logo-sidebar-item col-6 col-md-4">
      <a [routerLink]="homeSection" class="d-flex logo-link-section" (click)="logoSelected()">
        <img
          class="logo-sidebar-image"
          src="../../../assets/images/gallery/portal-logo.svg"
          alt="Lab4U Portal"
        />
        <h3
          [ngClass]="{ 'd-none': !toggleSidebar }"
          class="logo-sidebar-title my-auto pl-2 d-md-none"
        >
          Portal
        </h3>
      </a>
    </li>
    <li
      *ngFor="let submenu of menu.submenu"
      class="nh-nav-item align-content-center d-flex"
    >
      <ng-container *ngIf="!submenu.outside; else externalLinkAttr">
        <a class="nh-nav-link mx-auto" [routerLink]="submenu.url" (click)="leftSidebarSelected(submenu.url)">
          <img class="menu-icon" src="{{ submenu.icono }}" routerLinkActive="active"/>
          <p class="d-md-none menu-text my-auto">{{ submenu.titulo }}</p>
          <span class="nh-link-tooltip">{{ submenu.titulo }}</span>
        </a>
      </ng-container>
      <ng-template #externalLinkAttr>
        <a
          class="nh-nav-link mx-auto"
          href="{{submenu.url}}"
          target="_blank"
          (click)="supportSelected()"
        >
          <img class="menu-icon" src="{{ submenu.icono }}" />
          <p class="d-md-none menu-text my-auto">{{ submenu.titulo }}</p>
          <span class="nh-link-tooltip">{{ submenu.titulo }}</span>
        </a>
      </ng-template>
    </li>
  </ul>
</nav>

<!-- ============================================================== -->
<!-- End Left Sidebar  -->
<!-- ============================================================== -->
