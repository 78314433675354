export const PublicRoutes = {
  LOGIN: '/login',
  SIGN_UP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
};

export const PrivateRoutes = {
  DASHBOARD: '/dashboard',
  HOME: '/home',
  GETTING_STARTED: '/getting-started',
  CLASSROOMS: '/classrooms',
  TOOLS: '/tools',
  CATEGORIES: '/categories',
  EXP_PROFILE: '/experiments-profile/',
  TOPICS: '/topics',
  TRAINING_QUIZZES: '/training-quizzes',
  PLANNING_AI: '/planning-ai',
  INACAP: '/planning-ai-inacap',
};
