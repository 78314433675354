import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { SidebarService } from './sidebar.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ChangeAppService {
  constructor(
    public settings: SettingsService,
    public sidebarService: SidebarService,
  ) {}

  applyApp(app: string) {
    if (app === 'LAB4PHYSICS') {
      this.sidebarService.applyApp('LAB4PHYSICS');
      this.settings.applyTheme('red');
      return 'LAB4PHYSICS';
    }
    if (app === 'LAB4CHEMISTRY') {
      this.sidebarService.applyApp('LAB4CHEMISTRY');
      this.settings.applyTheme('purple');

      return 'LAB4CHEMISTRY';
    }
    if (app === 'LAB4BIOLOGY') {
      this.sidebarService.applyApp('LAB4BIOLOGY');
      this.settings.applyTheme('green');

      return 'LAB4BIOLOGY';
    }
    Swal.fire('Error', 'error', 'error');
    return '';
  }
}
