import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Modal
import { ModalUserTypeService } from '../../components/modal-user-type/modal-user-type.service';

@Injectable({
  providedIn: 'root'
})
export class SelectUserTypeGuard  {

  /**
   * Following variables declared
   * @param sidebarService Variable to show/hide modal
   * @param router Variable that manage the routes
   */
  constructor(
    public modalUserTypeService: ModalUserTypeService,
    public router: Router
  ) {}

  /**
   * Verify the user has a type which is created after the sign up
   */
  canActivate() {
    // tslint:disable-next-line: max-line-length
    if ( JSON.parse( localStorage.getItem('Type') ) === 'teacher' || JSON.parse( localStorage.getItem('Type') ) === 'student' || JSON.parse( localStorage.getItem('Type') ) === 'parent' ) {
      this.modalUserTypeService.hideModal();
      return true;
    } else {
      this.modalUserTypeService.showModal();
      this.router.navigate(['/sign-in']);
      return false;
    }
  }

}
