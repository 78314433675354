import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { ApiCallBase } from '../apiCallBase';
import { API_V3_URL } from 'src/app/config/config';
import { GlobalStateService } from 'src/app/global-state.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Injectable({
  providedIn: 'root',
})
export class TutoriaService extends ApiCallBase {
  correctedLanguage: string = 'EN-US';
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
    this.correctedLanguage = this.language === 'es' ? 'ES-LA' : 'EN-US';
  }

  generateTutorIAResponse(
    body: {
      content_keyword: string;
      messages: { role: string; content: string }[];
      user_id: string;
    },
    token: string,
  ) {
    const url = `${API_V3_URL}/tutoria-ai/chat`;
    return this.http
      .post(url, body, this.getHttpOptionsV3WithToken(token))
      .pipe(
        catchError((error) => {
          console.error('Error al enviar mensaje a TutorIA:', error);
          throw error;
        }),
      );
  }
}
