import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { API_V3_URL } from 'src/app/config/config';
import { documentItem } from 'src/app/models/document.model';
import { LanguageService } from '../language/language.service';
import { ApiCallBase } from './apiCallBase';
import { GlobalStateService } from 'src/app/global-state.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralDocsService extends ApiCallBase {
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
    this.language =
      this.language === 'es'
        ? 'ES-LA'
        : this.language === 'en'
        ? 'EN-US'
        : this.language;
  }

  getGeneralDocuments(language: string): Observable<documentItem[]> {
    let url = `${API_V3_URL}/portal/get-files?user_id=${this.userId}&category=GENERAL_DOCS&language=${this.language}`;

    return this.http.get<documentItem[]>(url, this.getHttpOptionsV3());
  }
}
