import { Component } from '@angular/core';
import { LanguageService } from '../services/language/language.service';
import { ManageAccountService } from '../services/api-lab4u/user-auth/manage-account.service';
import { ActivatedRoute } from '@angular/router';
declare function init_plugins();
@Component({
  selector: 'app-complete-delete-account',
  templateUrl: './complete-delete-account.component.html',
  styleUrls: ['./complete-delete-account.component.css'],
})
export class CompleteDeleteAccountComponent {
  language: string;
  requestConfirmed: boolean = false;
  errorCode: number = 0;
  loading: boolean = true;
  payload: string = '';

  constructor(
    private route: ActivatedRoute,
    private manageAccountService: ManageAccountService,
    private languageService: LanguageService,
  ) {}
  ngOnInit() {
    init_plugins();
    this.payload = this.route.snapshot.queryParamMap.get('payload') || '';
    this.language = this.languageService.getLanguage();

    this.completeDeleteAccount();
  }

  completeDeleteAccount() {
    this.manageAccountService
      .accountDataDeletionComplete(this.payload)
      .subscribe({
        next: (data) => {
          this.requestConfirmed = true;
          this.loading = false;
        },
        error: (error) => {
          this.errorCode = error.status;
          this.requestConfirmed = false;
          this.loading = false;
        },
      });
  }
  /**
   * Function to change language localStorage
   * @param language Variable to save the actual language
   */
  changeLanguage(language: string) {
    if (language === 'es' || language === 'en') {
      this.language = language;
      this.languageService.applyLanguage(this.language);
      window.location.reload();
    } else {
      return;
    }
  }
}
