<div
  *transloco="let text; read: 'EXPERIMENTS'"
  class="card-component w-100 mx-auto h-100 text-center"
>
  <img
    class="card-component-image img-fluid w-[152px] h-auto"
    [src]="scienceIcon"
  />
  <h3 class="mx-auto py-3 font-weight-bold">{{ scienceTitle }}</h3>
  <div class="mx-auto">
    <app-primary-button
      [changeApp]="science"
      [title]="text('GET-IN')"
      [linkTo]="dashboardSection"
      [background]="'primary-button-blue-bg'"
    ></app-primary-button>
  </div>

  <!-- <a (click)="applyApp(sciencex)" [routerLink]="dashboardSection" >
    <div class="css-button-fully-rounded text-center primary-button-blue-bg">
        <p>Open</p>
    </div>
</a> -->
</div>
