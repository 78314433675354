import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modals
import { ModalVideoService } from '../components/modal-video/modal-video.service';
import { ModalAppService } from '../components/modal-app/modal-app.service';
import { ModalPaywallService } from '../components/modal-paywall/modal-paywall.service';
import { ModalPdfService } from '../components/modal-pdf/modal-pdf.service';
import { ModalUserTypeService } from '../components/modal-user-type/modal-user-type.service';
import { ModalComingSoonService } from '../components/modal-coming-soon/modal-coming-soon.service';

import {
  UserService,
  SidebarService,
  LoginGuard,
  SelectAppGuard,
  SelectUserTypeGuard,
} from './service.index';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    SidebarService,
    UserService,
    LoginGuard,
    SelectAppGuard,
    SelectUserTypeGuard,
    ModalVideoService,
    ModalAppService,
    ModalPaywallService,
    ModalPdfService,
    ModalUserTypeService,
    ModalComingSoonService,
  ],
})
export class ServiceModule {}
