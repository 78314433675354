import { Component, OnInit } from '@angular/core';

// Modal service
import { ModalPaywallService } from './modal-paywall.service';
import { LanguageService } from '../../services/language/language.service';

/**
 * Component for the user to select an app
 */
@Component({
  selector: 'app-modal-paywall',
  templateUrl: './modal-paywall.component.html',
  styleUrls: ['./modal-paywall.component.css']
})
export class ModalPaywallComponent implements OnInit {

  /**
   * The language variable is declare
   * @param language Variable to save the actual language
   */
  language: string;


  /**
   * The modalVideoService variable is declare
   * @param modalPaywallService Variable to show/hide modal
   * @param languageServie Service that applies the selected language by the user
   */
  constructor(
    public modalPaywallService: ModalPaywallService,
    public languageServie: LanguageService
  ) {
    this.language = languageServie.getLanguage();
  }

  /**
   * @ignore
   */
  ngOnInit() {}

  /**
   * Function that allows to close the modal using the service of the modal
   */
  closeModal() {
    this.modalPaywallService.hideModal();
    return true;
  }

}
