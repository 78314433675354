import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Services
import { UserService } from '../services/user/user.service';
import { ModalComingSoonService } from '../components/modal-coming-soon/modal-coming-soon.service';
import { AppleLoginService } from '../services/apple-sign-in/apple-login.service';
import { MixpanelService } from '../services/analytics/mixpanel.service';

// Model
import { User } from '../models/user.model';

// Trasnlate
import { LanguageService } from '../services/language/language.service';
import { TranslocoService } from '@ngneat/transloco';

// Social login
import {
  SocialAuthService,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';

// Crypt
import * as sha1 from 'js-sha1';

// RxJs
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// SEO
import { SeoService } from '../services/seo/seo.service';

// Alerts
import Swal from 'sweetalert2';
import { PrivateRoutes } from '../config/config.routes';
import { GlobalStateService } from '../global-state.service';

/**
 * Declare plugins so then you can run it
 */
declare function init_plugins();

/**
 * Component to manage a login page
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * The userEmail variable is declare
   * @param userEmail Variable to save the user’s email
   */
  userEmail: string;
  /**
   * The rememberUser variable is declare
   * @param rememberUser Variable that shows if the user wants to save the e-mail at the localStorage
   */
  rememberUser = false;
  /**
   * The loading variable is declare
   * @param loading Variable to show/hide the loading
   */
  loading = false;
  /**
   * The language variable is declare
   * @param language Variable to save the actual language
   */
  language: string;

  private subscription: Subscription = new Subscription();

  firstNameApple: string;
  lastNameApple: string;
  emailApple: string;
  appleLogin: any;
  googleClientId = GoogleLoginProvider.PROVIDER_ID;
  /* urls */
  /**
   * @param redirectUrl If the user comes from another URL and was redirected to the login, the previous url is stored here
   */
  redirectUrl: any = '';
  dashboard_url: string = PrivateRoutes.HOME;

  tokenSHA: string = '';

  /**
   * Following variables declared
   * @param socioAuthServ Social networks promise
   * @param router Variable that manage the routes
   * @param userService Service that brings users data and functions
   * @param languageService Service that applies the selected language by the user
   * @param translocoService Service that applies the selected language by the user
   */
  constructor(
    private socioAuthServ: SocialAuthService,
    public router: Router,
    public userService: UserService,
    public appleService: AppleLoginService,
    private languageService: LanguageService,
    private translocoService: TranslocoService,
    public modalComingSoonService: ModalComingSoonService,
    public mixpanelService: MixpanelService,
    private seoService: SeoService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    public globalStateService: GlobalStateService,
  ) {}

  /**
   * The plugins run, and remember user
   */
  ngOnInit() {
    init_plugins();
    this.tokenSHA = this.globalStateService.globalData?.TokenSHA1;
    this.language = this.languageService.getLanguage();

    // SEO
    let desc: string;
    const t = 'TeacherPortal';
    if (this.language === 'es') {
      desc =
        'Herramientas para priorizar lo que realmente importa. Este portal está hecho por profesores para profesores.';
    } else {
      desc =
        'Tools to help you prioritize what matters most. This portal is made by teachers for teachers to support you!';
    }
    this.title.setTitle(t);
    this.seoService.generateTags({
      title: t,
      description: desc,
      slug: 'sign-in',
    });

    this.userEmail = localStorage.getItem('email') || '';
    if (this.userEmail.trim().length > 1) {
      this.rememberUser = true;
    }
    this.redirectUrl =
      this.activatedRoute.snapshot.queryParamMap.get('redirectUrl') ||
      PrivateRoutes.HOME;

    this.socioAuthServ.authState.subscribe((userInfo) => {
      let user = new User();
      user = userInfo;
      user.email = userInfo.email;
      user.language = this.language;
      user.login_type = 'GOOGLE';
      user.image = userInfo.photoUrl;
      user.third_party_id = sha1(userInfo.id + this.tokenSHA);
      this.subscription.add(
        this.userService.login(user).subscribe((resp) => {
          /**
           * If the user comes from another URL and was redirected to the login
           */
          if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
          } else {
            this.router.navigateByUrl(this.dashboard_url);
          }
        }),
      );
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  refreshToken(): void {
    this.socioAuthServ.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  /**
   * Function that allows the user to enter the web
   * @param form Login Form
   */
  signIn(form: NgForm) {
    this.loading = false;
    if (form.invalid) {
      return;
    }

    const user = new User(
      form.value.first_name,
      form.value.last_name,
      form.value.userEmail.toLowerCase(),
      (form.value.language = this.language),
      (form.value.login_type = 'LAB4U'),
      form.value.type,
      form.value.gender,
      form.value.userPass,
      form.value.biz_type,
      form.value.app,
      form.value.isGuest,
      form.value.institution,
      form.value.actual_deal,
      form.value.institution_id,
      form.value.usergroup,
      form.value.token,
      form.value.validated_acount,
      form.value.image,
      form.value.userId,
      form.value.user_type,
    );

    user.password = sha1(user.email + user.password + this.tokenSHA);

    this.subscription.add(
      this.userService
        .login(user, form.value.rememberUser)
        .subscribe((resp) => {
          this.loading = false;
          // this.router.navigate(['/dashboard']);
          if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
          } else {
            this.router.navigateByUrl(this.dashboard_url);
          }
          // this.mixpanelService.init(localStorage.getItem('userId'));
          // this.mixpanelService.track('sign_in', {});
        }),
    );
  }

  /**
   * Function that shows modal
   */
  showModal() {
    this.modalComingSoonService.showModal();
    return true;
  }

  /**
   * Function to change language with Transloco
   * @param language Variable to save the actual language of languageService
   */
  selectLanguage(language: string = this.languageService.getLanguage()) {
    this.translocoService.setActiveLang(language);
  }

  /**
   * Function to change language localStorage
   * @param language Variable to save the actual language
   */
  changeLanguage(language: string) {
    if (language === 'es' || language === 'en') {
      this.language = language;
      this.languageService.applyLanguage(this.language);
      window.location.reload();
    } else {
      return;
    }
  }
}
