import { Component } from '@angular/core';
import { ManageAccountService } from '../services/api-lab4u/user-auth/manage-account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '../services/language/language.service';

declare function init_plugins();
@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css'],
})
export class DeleteAccountComponent {
  deleteAccountForm: FormGroup;

  checkboxStatus: boolean = false;
  errorFlag: boolean = false;
  errorCode: number = 0;

  accountInfo: { email: string; language: string } = {
    email: '',
    language: '',
  };
  submitted: boolean = false;
  language: string;

  constructor(
    private manageAccountService: ManageAccountService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
  ) {}
  ngOnInit() {
    init_plugins();
    this.language = this.languageService.getLanguage();
    this.deleteAccountForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      checkboxStatus: [false, Validators.requiredTrue],
    });
  }

  buttonStatus(): boolean {
    return this.deleteAccountForm.valid;
  }

  isInvalidEmail(): boolean {
    const control = this.deleteAccountForm.get('email');
    return control.invalid && (control.dirty || control.touched);
  }

  sendAccountDataDeletionRequest() {
    this.accountInfo.email = this.deleteAccountForm.get('email').value;
    this.manageAccountService
      .accountDataDeletionRequest(this.accountInfo)
      .subscribe({
        next: (data) => {
          this.submitted = true;
        },
        error: (error) => {
          this.errorFlag = true;
          this.errorCode = error.status;
        },
      });
  }

  /**
   * Function to change language localStorage
   * @param language Variable to save the actual language
   */
  changeLanguage(language: string) {
    if (language === 'es' || language === 'en') {
      this.language = language;
      this.languageService.applyLanguage(this.language);
      window.location.reload();
    } else {
      return;
    }
  }
}
