import { Injectable } from '@angular/core';

/**
 * Service to manage the sidebar and the app of the page
 */
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  /**
   * The app variable is declared
   * @param app Variable to save the app
   */
  app: string;

  /**
   * The menuES variable is declared
   * @param menuES Variable to save the routes in Spanish
   */
  menuES: any = [
    {
      submenu: [
        {
          titulo: 'Home',
          icono: '../../../assets/images/sidebar/home-red.svg',
          url: '/home',
          outside: false,
        },
        {
          titulo: 'Recursos',
          icono: '../../../assets/images/sidebar/docs-red.svg',
          url: '/general-documents',
          outside: false,
        },
        {
          titulo: 'Ayuda',
          icono: '../../../assets/images/sidebar/help-red.svg',
          url: 'https://lab4u.zendesk.com/hc/en-us/requests/new',
          outside: true,
        },
        {
          titulo: 'Cerrar Sesión',
          icono: '../../../assets/images/sidebar/logout-red.svg',
          url: '/log-out',
          outside: false,
        },
      ],
    },
  ];

  /**
   * The menuEN variable is declared
   * @param menuEN Variable to save the routes in English
   */
  menuEN: any = [
    {
      submenu: [
        {
          titulo: 'Home',
          icono: '../../../assets/images/sidebar/home-red.svg',
          url: '/home',
          outside: false,
        },
        {
          titulo: 'Resources',
          icono: '../../../assets/images/sidebar/docs-red.svg',
          url: '/general-documents',
          outside: false,
        },
        {
          titulo: 'Help',
          icono: '../../../assets/images/sidebar/help-red.svg',
          url: 'https://lab4u.zendesk.com/hc/en-us/requests/new',
          outside: true,
        },
        {
          titulo: 'Log out',
          icono: '../../../assets/images/sidebar/logout-red.svg',
          url: '/log-out',
          outside: false,
        },
      ],
    },
  ];

  //* INTERNAL MENU  */
  internalMenuES: any = [
    {
      submenu: [
        {
          titulo: 'Para comenzar',
          icono: '../../../assets/images/sidebar/getting-started-red.svg',
          url: '/getting-started',
          outside: false,
        },
        {
          titulo: 'Herramientas',
          icono: '../../../assets/images/sidebar/tools-red.svg',
          url: '/tools',
          outside: false,
        },
        {
          titulo: 'Experimentos',
          icono: '../../../assets/images/sidebar/experiments-red.svg',
          url: '/categories',
          outside: false,
        },
        {
          titulo: 'Tutoriales',
          icono: '../../../assets/images/sidebar/tutorials-red.svg',
          url: '/tutorials',
          outside: false,
        },
        {
          titulo: 'Cerrar Sesión',
          icono: '../../../assets/images/sidebar/logout-red.svg',
          url: '/log-out',
          outside: false,
        },
      ],
    },
  ];
  internalMenuEN: any = [
    {
      submenu: [
        {
          titulo: 'Para comenzar',
          icono: '../../../assets/images/sidebar/getting-started-red.svg',
          url: '/getting-started',
          outside: false,
        },
        {
          titulo: 'Herramientas',
          icono: '../../../assets/images/sidebar/tools-red.svg',
          url: '/tools',
          outside: false,
        },
        {
          titulo: 'Experimentos',
          icono: '../../../assets/images/sidebar/experiments-red.svg',
          url: '/categories',
          outside: false,
        },
        {
          titulo: 'Tutoriales',
          icono: '../../../assets/images/sidebar/tutorials-red.svg',
          url: '/tutorials',
          outside: false,
        },
        {
          titulo: 'Cerrar Sesión',
          icono: '../../../assets/images/sidebar/logout-red.svg',
          url: '/log-out',
          outside: false,
        },
      ],
    },
  ];

  /**
   * Apply the app when the service starts
   */
  constructor() {
    this.loadApp();
  }

  /**
   * Function that saves the app at the localStorage
   */
  saveApp() {
    localStorage.setItem('App', JSON.stringify(this.app));
    return true;
  }

  /**
   * Function that brings the app information at localStorage
   */
  loadApp() {
    if (
      localStorage.getItem('App') !== 'undefined' ||
      localStorage.getItem('App') !== null
    ) {
      if (JSON.parse(localStorage.getItem('App'))) {
        this.app = JSON.parse(localStorage.getItem('App'));
        this.applyApp(this.app);
        return true;
      } else {
        this.app = 'undefined';
        localStorage.setItem('App', JSON.stringify(this.app));
        return false;
      }
    } else {
      this.applyApp(this.app);
    }
  }

  /**
   * Function that use the app on the page
   * @param app Variable that brings the app
   */
  applyApp(app: string) {
    if (app === 'undefined') {
      this.app = app;
      return false;
    } else {
      this.app = app;
      localStorage.setItem('App', JSON.stringify(app));
      this.saveApp();
      return true;
    }
  }

  /**
   * Function that returns the actual app
   */
  getApp() {
    if (
      localStorage.getItem('App') !== 'undefined' ||
      localStorage.getItem('App') !== null
    ) {
      this.app = JSON.parse(localStorage.getItem('App'));
      return this.app;
    } else {
      this.app = 'undefined';
      localStorage.setItem('App', JSON.stringify(this.app));
      return this.app;
    }
  }
}
