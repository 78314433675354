<!-- TOP NAVBAR -->
<header class="topbar">
  <div class="d-block d-md-none responsive-sidebar">
    <app-sidebar [sidebarElements]="sidebarElements"></app-sidebar>
  </div>
  <div class="navbar top-navbar navbar-expand navbar-background">
    <!-- MENU ELEMENTS -->
    <div class="navbar-collapse">
      
      <ul class="navbar-nav ml-auto">
 
        <!-- USER-PROFILE -->
        <li class="nav-item dropdown user-profile">
          <!-- DROPDOWN BUTTON -->
          <a
            class="nav-link dropdown-toggle waves-effect waves-dark"
            href=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            *ngIf="loading"
          >
            <div class="w-100 row mx-0 align-items-center">
              <!-- USERNAME -->
              <span class="user-name d-none d-sm-block">
                {{ 'HEADER.SAY-HI' | transloco }} {{ user.first_name }} {{ user.last_name }}
              </span>
              <!-- USER AVATAR -->
              <div class="resp-img-container my-auto profile-pic">
                <img
                  [src]="user.image ? (user.image | safe: 'resourceUrl') : '../../../assets/images/no-img.png'"
                  [alt]="user.first_name"
                  referrerpolicy="no-referrer"
                />
              </div>
              <!-- ARROW -->
              <span class="custom-arrow">
                <img src="./assets/images/custom-select.png" alt="" />
              </span>
            </div>
          </a>

          <!-- DROPDOWN BOX -->
          <div
            class="dropdown-menu dropdown-menu-right animated fadeIn dropdown-user-wrap"
          >
            <ul class="dropdown-user">
              <!-- USER DATA -->
              <li class="user-data">
                <div class="row w-100 m-0">
                  <div class="m-auto">
                    <div *ngIf="loading">

                      <div class="flex-row d-flex mx-auto mb-3">
                        <div class="resp-img-container m-auto user-data-profile-pic  d-flex">
                          <img 
                            class="rounded-circle"
                            [src]="user.image ? (user.image | safe: 'resourceUrl') : '../../../assets/images/no-img.png'"
                            [alt]="user.first_name"
                            referrerpolicy="no-referrer"
                          />
                          
                        </div>
                        <h5 class="user_attribute m-auto">
                           {{ user.first_name }} {{ user.last_name }}
                        </h5>
                      </div>

                      <div
                        class="text-muted cursor d-flex flex-wrap justify-content-between"
                      >
                        <p class="user_attribute">{{ 'HEADER.EMAIL' | transloco }}</p>
                        <p>{{ user.email }}</p>
                      </div>
                      <div
                        class="text-muted cursor d-flex flex-wrap justify-content-between"
                      >
                        <p class="user_attribute">{{ 'HEADER.INSTITUTION' | transloco }}</p>
                        <p>{{ user.institution }}</p>
                      </div>
                      <div
                        class="text-muted cursor d-flex flex-wrap justify-content-between"
                      >
                        <span class="user_attribute">{{ 'HEADER.ACCOUNT-TYPE' | transloco }}</span>
                        {{ user.usergroup }}
                      </div>
                    </div>

                    <div *ngIf="!loading">
                      <h4 class="cursor">{{ 'LOADING.TITLE' | transloco }}</h4>
                      <p class="text-muted cursor">
                        {{ 'LOADING.TITLE' | transloco }}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <!-- SEPARATOR -->
        <li class="nav-item dropdown mx-2" style="font-size: 30px">
          <label class="separation"> | </label>
        </li>

        <!-- LANGUAGE SELECTORS -->
        <li class="nav-item language flat-button row">
          <div class="my-auto">
            <a (click)="changeLanguage('en')" class="change-language pointer"
              >EN
            </a>
            <span class="cursor separation">|</span>
            <a (click)="changeLanguage('es')" class="change-language pointer">
              ES</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>
