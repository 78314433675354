import { Component, Input, OnInit } from '@angular/core';
import { PrivateRoutes } from 'src/app/config/config.routes';
import { SettingsService } from '../../services/settings/settings.service';
import Swal from 'sweetalert2';
import { SidebarService } from 'src/app/services/shared/sidebar.service';
import { ChangeAppService } from 'src/app/services/shared/change-app.service';

@Component({
  selector: 'app-apps-card',
  templateUrl: './apps-card.component.html',
  styleUrls: ['./apps-card.component.css'],
})
export class AppsCardComponent implements OnInit {
  @Input() scienceIcon: string = '';
  @Input() scienceTitle: string = '';
  @Input() scienceUrl: string = '';
  @Input() science: string = '';

  dashboardSection: string = PrivateRoutes.DASHBOARD;

  constructor(
    public settings: SettingsService,
    public sidebarService: SidebarService,
    public changeAppService: ChangeAppService,
  ) {}

  ngOnInit(): void {}

  applyApp(app: string) {
    this.changeAppService.applyApp(app);
  }
}
